import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'sound-content-footer',
    templateUrl: './content-footer.component.html',
    styleUrls: ['./content-footer.component.scss'],
    standalone: false
})
export class ContentFooterComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    public router: Router
  ) { }

}
