import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AppRoute } from '@app/shared/app.route.enum';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { LanguageHelper } from '@app/shared/functions/language.helper';
import { SubSubNavigationView } from '@app/shared/models/enums/SubSubNavigationView.enum';
import { InputModeConfiguration, InputModeService } from '@app/shared/services/input-mode.service';
import { ForbiddenService } from '@app/shared/services/local/forbidden.service';
import { NotFoundService } from '@app/shared/services/local/not-found.service';
import { SidebarService } from '@app/shared/services/local/side-bar.service';
import { filter } from 'rxjs';

const languageKey = 'language';

@Component({
    selector: 'sound-base-layout',
    templateUrl: './base-layout.component.html',
    styleUrls: ['./base-layout.component.scss'],
    standalone: false
})
export class BaseLayoutComponent extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  isForbidden: boolean = false;

  notFound: boolean = false;

  Languages = LanguageHelper.GetLanguages();

  currentLanguage: any;

  isInput: boolean = false;

  sideBarExpanded: boolean = false;

  subSideBarExpanded: boolean = false;

  subSubSideBarIsExpanded: boolean = false;

  currentScrollPosition: number = 0;

  showTopBar: boolean = true;

  hideSideBar: boolean = false;

  isBrowsePage: boolean = false;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private forbiddenService: ForbiddenService,
    private notFoundService: NotFoundService,
    private location: Location,
    private inputModeService: InputModeService,
    private sideBarService: SidebarService,
    public router: Router
  ) {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {

    window.addEventListener('scroll', this.scroll, true);

    super.addSubscription(
      this.inputModeService.configuration$
        .subscribe((configuration: InputModeConfiguration) => {
          this.isInput = configuration.isInput;
        })
    );

    super.addSubscription(
      this.forbiddenService
        .isForbidden$
        .subscribe((isForbidden: boolean) => {
          this.isForbidden = isForbidden;
        })
    );

    super.addSubscription(
      this.notFoundService
        .notFound$
        .subscribe((notFound: boolean) => {
          this.notFound = notFound;
        })
    );


    //this.subSideBarExpanded =  this.sideBarService.isBrowsePage;

    // if (this.router.url.includes(AppRoute.Browse)) {
    //   this.subSideBarExpanded = true;
    // }

    super.addSubscription(
      this.sideBarService.showSideBarExpanded$.subscribe({
        next: (expanded: boolean) => {
          this.sideBarExpanded = expanded;
        }
      })
    );

    //this.subSubSideBarIsExpanded = this.sideBarService.subSubSideBarIsExpanded;

    super.addSubscription(
      this.sideBarService.showSubBrowseBar$.subscribe({
        next: (value: [boolean, SubSubNavigationView]) => {
          this.subSubSideBarIsExpanded = value[0];
        }
      })
    );

    super.addSubscription(
      this.sideBarService.showBrowseSideBar$.subscribe({
        next: (value: boolean) => {
          this.subSideBarExpanded = value;
        }
      })
    );

    super.addSubscription(
      this.sideBarService.hideSideBar$.subscribe({
        next: (value: boolean) => {
          this.hideSideBar = value;
        }
      })
    );

    super.addSubscription(
      this.sideBarService.isBrowsePage$.subscribe({
        next: (value: boolean) => {
          this.isBrowsePage = value;
        }
      })
    )
  }
  

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  private scroll = (event: any): void => {
    const newScroll = event.srcElement.scrollTop;

    // Required for multiple scrolls
    if (event.srcElement.className.includes('content')) {
      if (this.currentScrollPosition > newScroll) {
        this.showTopBar = true;
      } else {
        this.showTopBar = false;
      }

      this.currentScrollPosition = newScroll;

      if (newScroll <= 0) {
        this.showTopBar = true;
      }
    }
  }

}
