import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkListLinkComponent } from './link-list-link.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [LinkListLinkComponent],
  declarations: [LinkListLinkComponent]
})
export class LinkListLinkModule { }
