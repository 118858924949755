import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JadFormFieldsSliderComponent } from './form-fields-slider.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSliderModule,
    MatDividerModule
  ],
  declarations: [JadFormFieldsSliderComponent],
  exports: [JadFormFieldsSliderComponent]
})
export class JadFormFieldsSliderModule { }
