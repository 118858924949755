import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { I18nService } from '@app/core/i18n.service';
import { AppRoute } from '@app/shared/app.route.enum';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { FileDropzoneError, FileDropzoneErrorType } from '@app/shared/components/files/file-dropzone/file-dropzone.component';
import { FileContainer } from '@app/shared/components/files/models/FileContainer';
import { Artist } from '@app/shared/models/classes/Artist';
import { Track } from '@app/shared/models/classes/Track';
import { TaskStatus } from '@app/shared/models/classes/WebhookTask';
import { TasksService } from '@app/shared/services/tasks.service';
import { TracksService } from '@app/shared/services/tracks.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, concatMap, filter, from, map, Observable, of, switchMap, take, timer } from 'rxjs';

export class UploadDialogData {
  isCurator: boolean;
  artistId: string;
  audios: FileContainer[] = [];
  erroneousFile: File = null;
  hasErrors: boolean = false;
}

@Component({
    selector: 'sound-upload-dialog',
    templateUrl: './upload-dialog.component.html',
    styleUrls: ['./upload-dialog.component.scss'],
    standalone: false
})
export class UploadDialogComponent extends BaseComponent implements OnInit {

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  progress: number = 1;

  currentFileIndex: number = 0;

  form: UntypedFormArray;

  errorMessage: string = '';

  hasUploaded: boolean = false;

  isDropped: boolean = false;

  selectedArtist: Artist;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UploadDialogData,
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    private fb: UntypedFormBuilder,
    private tracksService: TracksService,
    private router: Router,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private tasksService: TasksService,
    private toastr: ToastrService
  ) {
    super();

    this.form = this.fb.array([]);
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {

    // Check if there an error
    if (this.data.hasErrors === true) {
      this.errorMessage = this.translateService.instant('Upload.Your tracks must be in WAV format and cannot be larger than 10 MB');
    }

    // Iniate form array
    this.patchFormArray(this.data.audios);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  setArtist(artist: Artist): void {
    this.selectedArtist = artist;
  }

  patchFormArray(audios: FileContainer[]): void {
    for (const audio of audios) {

      const isOriginal: boolean = audios.findIndex(q => q === audio) === 0 &&
        this.form.controls.filter((control: UntypedFormGroup) => control.get('isOriginal').value === true).length === 0 ?
        true :
        false;

      const trackForm: UntypedFormGroup = this.fb.group({
        id: audio.id,
        isOriginal
      });

      trackForm.get('isOriginal')
        .valueChanges
        .subscribe((value: boolean) => {
          // Set other tracks to false
          if (value === true) {
            for (const control of this.form.controls) {
              if (control.get('id').value !== trackForm.get('id').value) {
                control.get('isOriginal').setValue(false);
              }
            }
          }
          // Always keep one track as original
          if (value === false) {
            // If array contains one true value return true
            if (this.form.controls.filter((control: UntypedFormGroup) => control.get('isOriginal').value === true).length === 0) {
              trackForm.get('isOriginal').setValue(true, { emitEvent: false });
            }
          }
        });

      this.form.push(trackForm);
    }
  }

  handleErrors(error: FileDropzoneError): void {
    if (!this.IsNullOrUndefined(error)) {
      this.data.hasErrors = true;

      if (error.type === FileDropzoneErrorType.Type) {
        this.errorMessage = this.translateService.instant('Upload.Your tracks must be in WAV format');
      }
      if (error.type === FileDropzoneErrorType.Size) {
        this.errorMessage = this.translateService.instant('Upload.Your tracks cannot be larger than 200 MB');
      }
      this.data.erroneousFile = error.file;
    }
  }

  changeFiles(files: FileContainer[]): void {
    this.data.erroneousFile = null;
    this.errorMessage = null;
    this.data.hasErrors = false;

    this.patchFormArray(files);
    this.data.audios.push(...files);
  }

  submit(): void {
    this.isLoading = true;

    const track: Track = Object.assign({
      artistId: this.selectedArtist?.id ?? this.data.artistId
    });

    const tasks$: Observable<any>[] = [];

    const uploads$: Observable<any>[] = [];

    super.addSubscription(
      this.tracksService
        .create(track)
        .pipe(
          switchMap(
            (createdTrack: Track) => {
              // Push create by id observable into uploads
              for (const audio of this.data.audios) {

                // TODO get isOriginal from form for id
                let asOriginal: boolean = this.data.audios.findIndex(q => q.id === audio.id) === 0;

                asOriginal = this.form.getRawValue().find(q => q.id === audio.id).isOriginal;

                uploads$.push(
                  this.tracksService.uploadTrackVariationByTrackId(createdTrack.id, audio, asOriginal),
                );
              }

              // Start uploading
              this.currentFileIndex = 1;

              return this.concatAll(uploads$)
                .pipe(
                  switchMap(
                    (taskIds: any[]) => {
                      // Analysing

                      // Push task polling observable into tasks
                      for (const taskId of taskIds) {
                        tasks$.push(
                          timer(0, 2000)
                            .pipe(
                              concatMap(
                                () => this.tasksService.getById(taskId)
                              )
                            )
                            .pipe(
                              filter(
                                result => result.status === TaskStatus.Erroneous || result.status === TaskStatus.Completed
                              )
                            )
                            .pipe(
                              take(1)
                            )
                        );
                      }

                      // Polling for task status
                      return this.concatAll(tasks$)
                    }
                  )
                );
            }
          )
        )
        .subscribe({
          next: (result: any) => {
            // TODO change when calls are available
            // console.log(result);
            this.hasUploaded = true;

            const resource = this.data.isCurator ? '/curator' : '/creator';

            this.router.navigateByUrl(this.i18nService.getAbsoluteUrlWithCurrentLanguage(AppRoute.Home), { skipLocationChange: true }).then(() =>
              this.router.navigate([resource]).then(() => {
                this.toastr.success(this.translateService.instant('Upload.The track has been successfully uploaded'));
                this.dialogRef.close(true);
              })
            );
          },
          complete: () => {
            this.isLoading = false;
          },
          error: (error: any) => {
            this.isLoading = false;

            switch (error.error.code.internalCode) {
              default:
                this.toastr.error(this.translateService.instant('Upload.The track could not be uploaded'));
                break;
            }
          }
        })
    );

    this.increaseProgress();
  }

  increaseProgress(): void {
    // TODO change when calls are available
    setInterval(() => {
      if (this.progress < 100) {
        this.progress += 1;
      } else {
        this.progress = 0;
      }
    }, 50);
  }

  
}
