import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { SideBarActionsModule } from './actions/side-bar-actions.module';
import { SideBarHeaderModule } from './header/side-bar-header.module';
import { SideBarNavigationModule } from './navigation/side-bar-navigation.module';
import { SideBarComponent } from './side-bar.component';
import { SideBarSubnavigationModule } from './subnavigation/side-bar-subnavigation.module';
import { SideBarSubsubnavigationModule } from './subsubnavigation/side-bar-subsubnavigation.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    SharedModule,
    SideBarActionsModule,
    SideBarHeaderModule,
    SideBarNavigationModule,
    MatIconModule,
    SideBarSubnavigationModule,
    SideBarSubsubnavigationModule
  ],
  declarations: [SideBarComponent],
  exports: [
    SideBarComponent,
    SideBarActionsModule,
    SideBarHeaderModule,
    SideBarNavigationModule
  ]
})
export class SideBarModule { }
