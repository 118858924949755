<!-- WRAPPER -->
<div class="music-player-controls__wrapper flex flex-row items-center">

  <!-- BACK -->
  <div class="music-player-controls__back flex">
    <mat-icon class="large cursor-pointer" [svgIcon]="'arrow_simple_left'" (click)="loadPreviousTrack()">
    </mat-icon>
  </div>

  <ng-container *ngIf="isPlaying === false; else pauseControl;">
    <!-- PLAY -->
    <div class="music-player-controls__play flex">
      <mat-icon class="xtra-large cursor-pointer" [svgIcon]="'play'" (click)="play()">
      </mat-icon>
    </div>
  </ng-container>
  <ng-template #pauseControl>
    <!-- PAUSE -->
    <div class="music-player-controls__pause flex">
      <mat-icon class="xtra-large cursor-pointer" [svgIcon]="'pause'" (click)="pause()">
      </mat-icon>
    </div>
  </ng-template>

  <!-- NEXT -->
  <div class="music-player-controls__next flex">
    <mat-icon class="large cursor-pointer" [svgIcon]="'arrow_simple_right'" (click)="loadNextTrack()">
    </mat-icon>
  </div>

</div>