import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { JadFormFieldsMultiRangeComponent } from './form-fields-multi-range.component';


@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSliderModule,
    MatDividerModule
  ],
  declarations: [JadFormFieldsMultiRangeComponent],
  exports: [JadFormFieldsMultiRangeComponent]
})
export class JadFormFieldsMultiRangeModule { }
