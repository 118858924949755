<div *ngIf="mode === 'Intro'">
  <h2 class="cookie-title text-center">{{'Cookie.We use cookies' | translate}}</h2>
  <p class="cookie-content text-center mt-3">
    {{'Cookie.We use cookies to provide our store, to keep it secure and to continue to provide you with a great searching and purchasing experience.' | translate}}
  </p>
  <div class="cookie-actions flex flex-row justify-between items-center gap-4">
    <!-- CANCEL -->
    <sound-button-default [displayText]="'Cookie.Accept all' | translate" [colorClass]="'purple'" (onClick)="accept()">
    </sound-button-default>
    <!-- SUBMIT -->
    <sound-button-default [displayText]="'Cookie.Customize' | translate" [colorClass]="'default'"
      (onClick)="mode = 'Manage'">
    </sound-button-default>
  </div>
</div>
<div *ngIf="mode === 'Manage'">
  <h2 class="cookie-title text-center">Cookies anpassen</h2>
  <p class="cookie-content text-center mt-3">
    {{'Cookie.Learn more about our use of cookies in our ' | translate}} 
    <a [soundtaxiRouterLink]="'/privacy-policy'">
      {{'Cookie.privacy policy' | translate }}
    </a>.
  </p>
  <div class="cookie-toggles mb-8">
    <jad-form-fields-checkbox [control]="necessaryFormControl" [class]="'pb-5'">
      <p style="font-family: 'Montserrat-Semibold'; margin-bottom: 6px;" class="text-xl pt-6">
        {{'Cookie.Necessary' | translate }}
      </p>
      <p class="text-secondary-gray-20">
        {{'Cookie.Are required for the website to work' | translate }}
      </p>
    </jad-form-fields-checkbox>
  </div>
  <div class="cookie-actions flex flex-row justify-between items-center gap-4">
    <!-- CANCEL -->
    <sound-button-default [displayText]="'Cookie.Accept all' | translate" [colorClass]="'purple'" (onClick)="accept()">
    </sound-button-default>
    <!-- SUBMIT -->
    <sound-button-default [displayText]="'Cookie.Save' | translate" [colorClass]="'default'" (onClick)="save()">
    </sound-button-default>
  </div>
</div>