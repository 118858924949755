import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { WaveformLoadingService } from '@app/shared/services/local/waveform-loading.service';
import { NavigationLinkModule } from '../../navigation/link/navigation-link.module';
import { SideBarNavigationComponent } from './side-bar-navigation.component';

@NgModule({
  imports: [
    SharedModule,
    NavigationLinkModule,
    RouterModule
  ],
  declarations: [SideBarNavigationComponent],
  exports: [SideBarNavigationComponent],
  providers: [WaveformLoadingService]
})
export class SideBarNavigationModule { }
