import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, AbstractControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { BaseTextFormFieldComponent } from '../base/base-form-field.component';

@Component({
    selector: 'jad-form-fields-slider',
    templateUrl: './form-fields-slider.component.html',
    styleUrls: ['./form-fields-slider.component.scss'],
    standalone: false
})
export class JadFormFieldsSliderComponent extends BaseTextFormFieldComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  leftDisplacementDistance: number = 8;

  shouldDisplay: boolean = true;

  widthDistance: number = 100;

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() step: number = 1;

  @Input() sliderWidth: number = 144;

  @Input() withMoreThanMax: boolean = false;

  @Input() shouldDisplayLimits: boolean = false;

  @Input() shouldDisplayValue: boolean = false;

  @Input() customLabelFormatter: (value: number) => string;

  // The display can be above the thumb or on the edge 
  @Input() valuesDisplayType: 'Thumb' | 'Edge' = 'Thumb';

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(protected cdr: ChangeDetectorRef, private fb: UntypedFormBuilder) {
    super(cdr);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    const min = this.control.get('min').value;
    const max = this.control.get('max').value;

    this.getAsFormGroup(this.control).addControl('currentMax', this.fb.control(max), { emitEvent: false });
    this.getAsFormGroup(this.control).addControl('currentMin', this.fb.control(min), { emitEvent: false });

    this.leftDisplacementDistance = this.calculateDistance(min, 1);
    this.widthDistance = this.calculateDistance(max - min, -2);

    this.checkLimitsDisplay();

    super.addSubscription(
      this.control.get('currentMax').valueChanges
        .pipe(
          debounceTime(200),
          distinctUntilChanged()
        )
        .subscribe({
          next: (value: number) => {
            const currentMin = this.control.value.currentMin;

            // Update the actual values of the min and max
            if (value < currentMin) {
              this.control.get('min').setValue(value, { emitEvent: true });
              this.control.get('max').setValue(currentMin);
            } else {
              this.control.get('max').setValue(value, { emitEvent: true });
              this.control.get('min').setValue(currentMin);
            }

            this.checkLimitsDisplay();
          }
        })
    );

    super.addSubscription(
      this.control.get('currentMin').valueChanges
        .pipe(
          debounceTime(200),
          distinctUntilChanged()
        )
        .subscribe({
          next: (value: number) => {
            const currentMax = this.control.value.currentMax;

            // Update the actual values of the min and max
            // The emitting on the last value is so that the value changes can be captured on the outside
            if (value < currentMax) {
              this.control.get('min').setValue(value, { emitEvent: false });
              this.control.get('max').setValue(currentMax);
            } else {
              this.control.get('max').setValue(value, { emitEvent: false });
              this.control.get('min').setValue(currentMax);
            }

            this.checkLimitsDisplay();
          }
        })
    );

    super.addSubscription(
      this.control.get('min').valueChanges.subscribe({
        next: (value: number) => {
          if (value <= this.control.get('currentMax').value && value !== this.control.get('currentMin').value) {
            this.control.get('currentMin').setValue(value, { emitEvent: false });

            this.checkLimitsDisplay();
            this.updateDeterminantAndLimitsDisplay('min', value);
          }
        }
      })
    );

    super.addSubscription(
      this.control.get('max').valueChanges.subscribe({
        next: (value: number) => {
          if (value !== this.control.get('currentMax').value && value >= this.control.get('currentMin').value) {
            this.control.get('currentMax').setValue(value, { emitEvent: false });


            this.checkLimitsDisplay();
            this.updateDeterminantAndLimitsDisplay('max', value);
          }
        }
      })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  getAsFormGroup(control: AbstractControl): UntypedFormGroup {
    return control as UntypedFormGroup;
  }

  formatLabelMax(value: number): string {
    if (value === this.max) {
      return `${value}+`;
    }

    return `${value}`;
  }

  updateDeterminantAndLimitsDisplay(type: 'min' | 'max', value: number): void {
    let max = this.control.get('currentMax').value;
    let min = this.control.get('currentMin').value;

    switch (type) {
      case 'max':
        max = value;
        break;
      case 'min':
        min = value;
        break;
    }

    if (min > max) {
      const aux = min;
      min = max;
      max = aux;
    }

    // Displacing the width and the left displacement of the distance by -2 and 1 respectively, so that it does not show outside the thumb
    // Note that the 1 is necessary for each side, but since the left displacement by 1 moves the width as well by 1, an additional pixel
    // is required to be moved
    this.widthDistance = this.calculateDistance(max - min, 0);
    this.leftDisplacementDistance = this.calculateDistance(min, 1);

    this.checkLimitsDisplay();
  }

  getFirstValueInOrder(first: number, second: number, order: 'ascending' | 'descending' = 'ascending'): number {
    switch (order) {
      case 'ascending':
        return first < second ? first : second;
      case 'descending':
        return first > second ? first : second;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  private calculateDistance(baseDistance: number, displacement: number): number {
    // This computes the distance in pixels based on a base distance and a pixel displacement
    // this.sliderWidth / (this.max - this.min) represents the numerical width of 1 pixel in the view
    // considering that the position of the max and the min represent the first and last pixel respectively
    return baseDistance * this.sliderWidth / (this.max - this.min) + displacement;
  }

  private checkLimitsDisplay(): void {
    // 8 represents the width of a character for font size 12
    this.shouldDisplay = this.sliderWidth - this.widthDistance - this.leftDisplacementDistance > 8
      * (this.max.toString().length + (this.withMoreThanMax ? 1 : 0));
  }

}

