<sound-entity-searcher #entitySearcher [displaySelectedValue]="displaySelectedValue" [disabled]="disabled"
  [labelSelector]="labelSelector" [customOptionClass]="customOptionClass" [panelWidth]="panelWidth"
  [getEntities$]="getEntities$" [label]="label" [value]="value[0]" [placeholder]="placeholder"
  (valueChanged)="valueChanged.emit($event)" [required]="required" [error]="error">

  <ng-container *entitySearcherRow="let artist">
    <div fxLayout fxLayoutGap="12px" style="align-items: center; padding-top: 6px; padding-bottom: 6px;">
      <div fxFlex="1 1 auto" style="line-height: 25px;">
        <span>{{ artist?.pseudonym }}</span>
        <br />
        <small>
          {{ artist?.companyName }}
        </small>
      </div>
    </div>
  </ng-container>

</sound-entity-searcher>