import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BasketSideBarComponent } from '@app/basket/side-bar/basket-side-bar.component';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { I18nService } from '@app/core/i18n.service';
import { LicenseSelectorDialogComponent, LicenseSelectorDialogData } from '@app/licenses/shared/license-selector-dialog/license-selector-dialog.component';
import { AppRoute } from '@app/shared/app.route.enum';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { __ } from '@app/shared/functions/object.functions';
import { Track, TrackVariationFile } from '@app/shared/models/classes/Track';
import { FavoritesType } from '@app/shared/models/enums/FavoritesType.enum';
import { FilesService } from '@app/shared/services/files.service';
import { DownloadsHistoryService } from '@app/shared/services/local/downloads-history.service';
import { FavoritesService } from '@app/shared/services/local/favorites.service';
import { MusicPlayerService } from '@app/shared/services/local/music-player.service';
import { RedirectService } from '@app/shared/services/redirect.service';
import { SimilaritySearchRoute } from '@app/similarity-search/shared/similarity-search.route.enum';
import { AddToProjectDialogComponent, AddToProjectDialogData } from '@app/tracks/shared/add-to-project-dialog/add-to-project-dialog.component';
import saveAs from 'file-saver';
import { concatMap, of } from 'rxjs';

@Component({
    selector: 'sound-music-player-actions',
    templateUrl: './music-player-actions.component.html',
    styleUrls: ['./music-player-actions.component.scss'],
    standalone: false
})
export class MusicPlayerActionsComponent extends BaseComponent implements OnInit {

  /// -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // ------------------------------------------------------------------------------------------------------

  private _item: Track;
  @Input()
  set item(item: Track) {
    if (
      __.IsNullOrUndefinedOrEmpty(this._item) ||
      __.IsNullOrUndefinedOrEmpty(item) ||
      item.id !== this._item.id
    ) {
      this._item = item;
    }
  }
  get item(): Track {
    return this._item;
  }

  @Input() currentIndex: number = 0;

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // ------------------------------------------------------------------------------------------------------

  constructor(
    private favoritesService: FavoritesService,
    private dialog: MatDialog,
    private i18nService: I18nService,
    private filesService: FilesService,
    private downloadsHistoryService: DownloadsHistoryService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private redirectService: RedirectService,
    private musicService: MusicPlayerService
  ) {
    super();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // ------------------------------------------------------------------------------------------------------

  ngOnInit() {
    super.addSubscription(
      this.musicService.trackProjectChanges$.subscribe({
        next: (changes: { trackId: string, projectIds: string[] }) => {
          if (changes.trackId === this.item.id) {
            this.item.projectIds = changes.projectIds;
          }
        }
      })
    );
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // ------------------------------------------------------------------------------------------------------

  toggleFavorites(): void {
    this.favoritesService.toggleFavoriteByType(this.item, FavoritesType.Track).subscribe();
  }

  isFavorite(): boolean {
    return this.favoritesService.isFavoriteByType(this.item?.id, FavoritesType.Track);
  }

  openAddToProjectDialog(): void {
    super.addSubscription(
      this.dialog.open(AddToProjectDialogComponent, {
        data: Object.assign(new AddToProjectDialogData(), {
          trackId: this.item?.id,
          projectIds: this.item?.projectIds
        })
      }).afterClosed()
        .subscribe({
          next: (result: any) => {
            if (!__.IsNullOrUndefinedOrEmpty(result)) {
              this.item.projectIds.push(...result);
              this.musicService.updateProjectsForTrack(this.item.id, this.item.projectIds);
            }
          }
        })
    );
  }

  openShareDialog(): void { }

  openSimilaritySearchDialog(): void {
    this.musicService.pause(undefined, true);
    this.musicService.pause(undefined);

    this.router.navigate([this.i18nService.getAbsoluteUrlWithCurrentLanguage(`${AppRoute.SimilaritySearch}/${SimilaritySearchRoute.Track}`), this.item?.id]);
  }

  downloadTrackVariationByResolution(resolution: string): void {

    // Todo: Get the correct variation to download
    const files: TrackVariationFile[] = this.item?.variations[this.currentIndex].files || [];
    const fileId: string = files.find(q => q.type === `${resolution}`)?.id || '';

    super.addSubscription(
      this.filesService
        .downloadFile(`${fileId}`)
        .subscribe({
          next: (data: { blob: Blob, name: string }) => {
            saveAs(data.blob, data.name);
            this.downloadsHistoryService.addDownloadedTrack(this.item);
          },
          error: (error: any) => {
            console.error(error);
          }
        })
    );
  }

  openLicenseSelectorDialog(): void {
    super.addSubscription(
      this.dialog.open(LicenseSelectorDialogComponent, {
        data: Object.assign(new LicenseSelectorDialogData(), {
          track: this.item
        }),
        maxWidth: '58.571rem'
      }).afterClosed()
        .pipe(
          concatMap((response: any) => {
            if (this.IsNullOrUndefinedOrEmpty(response)) {
              return of(null);
            }

            return this.dialog.open(BasketSideBarComponent, {
              position: {
                top: '0px',
                right: '0px',
                bottom: '0px'
              },
              width: '28.571rem',
              height: '100vh'
            }).afterClosed();
          })
        )
        .subscribe({
          next: (shouldCheckout: any) => {
            if (shouldCheckout === true) {
              let route = `${AppRoute.Login}`;

              if (this.authenticationService.isAuthenticated()) {
                route = `${AppRoute.Checkout}`;
              } else {
                // redirect the user after login to route
                this.redirectService.setRedirectUri(`${AppRoute.Checkout}`);
              }

              this.router.navigateByUrl(this.i18nService.getAbsoluteUrlWithCurrentLanguage(route));
            }
          }
        })
    );
  }

}
