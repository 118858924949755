<div class="ai-powered__container flex flex-col items-start">

  <!-- TITLE -->
  <h1 class="text-3xl font-bold mb-6">{{ 'Ai Powered.Title' | translate }}</h1>

  <!-- INTRO -->
  <p class="text-lg mb-15" [innerHTML]="'Ai Powered.Intro' | translate | safe: 'html'"></p>


  <!-- DYNAMIC SECTIONS -->
  <div *ngFor="let section of ['TuneMatch', 'Mp3', 'TextSearch', 'Similarity', 'StandardSearch', 'RangeSelector']" class="mb-12">

    <!-- TITLE -->
    <ng-container *ngIf="'Ai Powered.' + section + 'Title' | translate as sectionTitle">
      <h2 class="text-2xl font-bold mb-6 ai-title">{{ sectionTitle }}</h2>
    </ng-container>

    <!-- Layout-Auswahl basierend auf der JSON-Variable -->
    <ng-container *ngIf="'Ai Powered.' + section + 'Layout' | translate as sectionLayout">
      
      <!-- FALL 1: Bild über ganze Breite + Text LINKS (Full-Layout) -->
      <div *ngIf="sectionLayout === 'full'" class="w-full">
        
        <!-- Wenn es sich um das GIF (TextSearchImage) handelt, begrenze die Größe -->
        <img *ngIf="section === 'TextSearch' && ('Ai Powered.' + section + 'Image') | translate"
             [src]="('Ai Powered.' + section + 'Image') | translate"
             [alt]="('Ai Powered.' + section + 'Title') | translate"
             class="gif-image mb-6">

        <!-- Alle anderen Bilder bleiben Full-Width -->
        <img *ngIf="section !== 'TextSearch' && ('Ai Powered.' + section + 'Image') | translate"
             [src]="('Ai Powered.' + section + 'Image') | translate"
             [alt]="('Ai Powered.' + section + 'Title') | translate"
             class="w-full mb-6">
        
        <!-- TEXTBLOCK unter dem Bild mit erzwungener LINKS-AUSRICHTUNG -->
        <div class="html-content w-full max-w-3xl ml-auto mr-auto text-left !text-left"
             [innerHTML]="('Ai Powered.' + section + 'Content') | translate | safe: 'html'">
        </div>
      </div>

      <!-- FALL 2: Bild links oder rechts -->
      <div *ngIf="sectionLayout !== 'full'" class="flex flex-col md:flex-row items-center" 
           [ngClass]="{'md:flex-row-reverse': sectionLayout === 'left'}">
        
        <!-- TEXTBLOCK -->
        <div class="html-content w-full md:w-1/2 px-4 text-left"
             [innerHTML]="('Ai Powered.' + section + 'Content') | translate | safe: 'html'">
        </div>

        <!-- IMAGE mit Originalgröße, falls es StandardSearch ist -->
        <img *ngIf="section === 'StandardSearch' && ('Ai Powered.' + section + 'Image') | translate"
             [src]="('Ai Powered.' + section + 'Image') | translate"
             [alt]="('Ai Powered.' + section + 'Title') | translate"
             class="original-size mb-6">

        <!-- Andere Bilder, die links/rechts sind, behalten die standardmäßige Breite -->
        <img *ngIf="section !== 'StandardSearch' && ('Ai Powered.' + section + 'Image') | translate"
             [src]="('Ai Powered.' + section + 'Image') | translate"
             [alt]="('Ai Powered.' + section + 'Title') | translate"
             class="w-full md:w-1/2 mb-6 md:mb-0">
        
      </div>

    </ng-container>

  </div>
 
  <div class="video-section mt-4">

    <!-- Headline -->
    <h2 class="text-2xl font-bold mb-4 ai-title">{{ 'Ai Powered.VideoTitle' | translate }}</h2>
  
    <!-- Beschreibung -->
    <p class="text-base mb-6">{{ 'Ai Powered.VideoDescription' | translate }}</p>
  
  
    <div class="video-container">
      <iframe src="https://player.vimeo.com/video/1062998694?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
              frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              style="position:absolute;top:0;left:0;width:100%;height:100%;" 
              title="How to use SOUNDTAXI's search powered by AI">
      </iframe>
    </div>
  
  </div>
  

  <!-- BUTTON -->
  <div class="dolby-atmos__button text-center mt-8 mb-8 flex flex-row">
    <sound-button-default (onClick)="scrollUp()" [icon]="'arrow_simple_right'" [order]="'icon-last'" [displayText]="'Dolby Atmos.Scroll Up' | translate">
    </sound-button-default>
  </div>

</div>
