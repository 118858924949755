import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsDefaultModule } from '../buttons/default/buttons-default.module';
import { JadFormFieldsCheckboxModule } from '../form-fields/checkbox/form-fields-checkbox.module';
import { CookieDialogComponent } from './cookie-dialog.component';
import { SoundtaxiRouterLinkWithHrefModule } from '@app/shared/directives/router-link/router-link.module';

@NgModule({
    imports: [
        SharedModule,
        TranslateModule,
        RouterModule,
        MatDialogModule,
        MatButtonModule,
        SoundtaxiRouterLinkWithHrefModule,
        MatCheckboxModule,
        ButtonsDefaultModule,
        JadFormFieldsCheckboxModule
    ],
    declarations: [CookieDialogComponent],
    exports: [CookieDialogComponent]
})
export class CookieDialogModule { }
