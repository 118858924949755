import { Component, OnInit, HostListener } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { MusicPlayerService } from '@app/shared/services/local/music-player.service';
import { debounceTime, fromEvent } from 'rxjs';

@Component({
    selector: 'sound-music-player-volume',
    templateUrl: './music-player-volume.component.html',
    styleUrls: ['./music-player-volume.component.scss'],
    standalone: false
})
export class MusicPlayerVolumeComponent extends BaseComponent implements OnInit {

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // ------------------------------------------------------------------------------------------------------

  volumeForm: UntypedFormGroup;

  isDesktop: boolean = false;

  lastVolumeLevel: number = 50;

  muteToggleIcon: 'volume_up' | 'volume_down' | 'volume_mute' = 'volume_up';

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private musicPlayerService: MusicPlayerService,
    private fb: UntypedFormBuilder
  ) {
    super();

    this.volumeForm = this.fb.group({
      min: 0,
      max: 100  // Standardlautstärke
    });
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    // Erkennen, ob Desktop oder Mobilgerät
    if (window.innerWidth > 767) {
      this.isDesktop = true;
    } else {
      this.isDesktop = false;
    }

    // Anpassen der Desktop/Mobilanzeige bei Größenänderung
    super.addSubscription(
      fromEvent(window, 'resize')
        .pipe(
          debounceTime(50)
        )
        .subscribe({
          next: () => {
            if (window.innerWidth > 767) {
              this.isDesktop = true;
            } else {
              this.isDesktop = false;
            }
          }
        })
    );

    // Lautstärkeänderungen vom Service abonnieren
    super.addSubscription(
      this.musicPlayerService
        .volumeChanges$
        .subscribe({
          next: ({ id, volume }) => {
            if (volume !== this.volumeForm.get('max').value) {
              this.volumeForm.get('max').setValue(volume);
            }
            if (volume !== 0) {
              this.lastVolumeLevel = volume;
            }
          }
        })
    );

    // Änderungen am Lautstärkeregler überwachen und Lautstärke setzen
    super.addSubscription(
      this.volumeForm
        .get('max')
        .valueChanges
        .subscribe({
          next: (value: number) => {

            // Lautstärke im Player setzen (ohne ID)
            this.musicPlayerService.setVolumeLevel('', value);  // FIX: Volume an den Player übergeben

            // Icon basierend auf dem Lautstärkelevel aktualisieren
            if (value === 0) {
              this.muteToggleIcon = 'volume_mute';
            } else if (value < 50) {
              this.muteToggleIcon = 'volume_down';
            } else if (value >= 50) {
              this.muteToggleIcon = 'volume_up';
            }
          }
        })
    );
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // ------------------------------------------------------------------------------------------------------

  toggleMute() {
    if (this.volumeForm.get('max').value > 0) {
      this.volumeForm.get('max').setValue(0);
      return;
    }
    this.volumeForm.get('max').setValue(this.lastVolumeLevel);
  }

  // Lautstärke per Tasten (+ und -) steuern
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {

      // Prüfen, ob ein Eingabefeld (Input oder Textarea) fokussiert ist
  const activeElement = document.activeElement as HTMLElement;
  if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA' || activeElement.isContentEditable)) {
    return; // Nichts tun, wenn ein Eingabefeld fokussiert ist
  }

    const currentVolume = this.volumeForm.get('max').value;

  // Überprüfen, ob die Taste + oder - gedrückt wurde
  if (event.code === 'Equal' || event.code === 'NumpadAdd') {
    event.preventDefault(); // Verhindert, dass andere Elemente auf die Taste reagieren
    this.increaseVolume(currentVolume);
  }

  if (event.code === 'Minus' || event.code === 'NumpadSubtract') {
    event.preventDefault(); // Verhindert, dass andere Elemente auf die Taste reagieren
    this.decreaseVolume(currentVolume);
  }
}

  increaseVolume(currentVolume: number) {
    const newVolume = Math.min(currentVolume + 10, 100);  // Erhöhe Lautstärke um 10, max 100
    this.volumeForm.get('max').setValue(newVolume);
  }

  decreaseVolume(currentVolume: number) {
    const newVolume = Math.max(currentVolume - 10, 0);  // Verringere Lautstärke um 10, min 0
    this.volumeForm.get('max').setValue(newVolume);
  }
}
