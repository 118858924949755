<!-- CONTAINER -->
<div class="mt-24 md:mt-48 flex flex-col items-start custom-container-width">

  <!-- HEADER -->
  <h1 class="artist-enquiry__header mb-8">
    <strong>
      {{ 'Artist Enquiry.Artist Enquiry' | translate }}
    </strong>
  </h1>

  <!-- CONTENT -->
  <div class="mb-16 md:mb-40 text-left custom-max-width">

    <div class="html-content" [innerHTML]="'Artist Enquiry.Content' | translate | safe: 'html'">
    </div>

  </div>

  <!-- BUTTON -->
  <div class="artist-enquiry__button text-center mb-8 flex flex-row custom-max-width">
    <sound-button-default (onClick)="scrollUp()" [icon]="'arrow_simple_right'" [order]="'icon-last'"
      [displayText]="'Artist Enquiry.Scroll Up' | translate">
    </sound-button-default>
  </div>

</div>