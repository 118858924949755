<!-- SIDE BAR -->
<div class="sidebar__container overflow-hidden flex-row h-screen max-h-screen top-0" [ngClass]="{ 'flex': showBrowseSidebar === true }">
  <div [ngClass]="{'sidebar flex-initial flex-col h-screen max-h-screen': true, 'sidebar--expanded': isExpanded === true, 'hidden': hideSideBar === true}">

    <!-- HEADER -->
    <div class="sidebar__header flex-initial flex flex-row px-7 justify-center">
      <sound-side-bar-header></sound-side-bar-header>
    </div>

    <!-- NAVIGATION -->
    <div class="flex-initial">
      <sound-side-bar-navigation></sound-side-bar-navigation>
    </div>

    <!-- ACTIONS -->
    <div class="flex-initial">
      <sound-side-bar-actions></sound-side-bar-actions>
    </div>

  </div>

  <!-- SUBNAVIGATION -->
  <div class="sidebar sub-sidebar flex-initial flex-col h-screen max-h-screen overflow-y-auto"
    *ngIf="showBrowseSidebar">

    <div class="pt-13 pr-0 pb-16 md:pb-5 md:pt-6">
      <sound-side-bar-subnavigation>
      </sound-side-bar-subnavigation>
    </div>
  </div>

  <div class="absolute md:hidden" style="top: 20px; left: 204px; z-index: 1000;" *ngIf="!IsNullOrUndefinedOrEmpty(subSubNavigationView)">
    <mat-icon [svgIcon]="'close_navigation'" (click)="setNestedActiveSubSubNavigation(null)" class="close-navigation"></mat-icon>
  </div>

  <div class="absolute md:hidden" style="top: 20px; left: 167px;" *ngIf="showBrowseSidebar === true && IsNullOrUndefinedOrEmpty(subSubNavigationView)">
    <mat-icon [svgIcon]="'close_navigation'" (click)="toggleBrowseSidebar(false)" class="close-navigation"></mat-icon>
  </div>

  <!-- SUBNAVIGATION -->
  <div class="sidebar sub-sub-sidebar flex-initial flex-col h-screen max-h-screen overflow-hidden"
    *ngIf="!IsNullOrUndefinedOrEmpty(subSubNavigationView)">

    <sound-side-bar-subsubnavigation [view]="subSubNavigationView">
    </sound-side-bar-subsubnavigation>
  </div>
</div>

<div *ngIf="showBrowseSidebar === false && isBrowsePage === true" (click)="toggleBrowseSidebar(true)" [ngClass]="isMusicPlayerExpanded === true ? 'top-20' : 'top-20'" class="cursor-pointer fixed lg:hidden z-1000 right-0 w-10 h-10 bg-primary-no-black">
  <mat-icon style="font-size: 40px; width: 40px; height: 40px;" svgIcon="filter"></mat-icon>
</div>