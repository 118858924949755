import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { ApplicationUser } from '@app/shared/models/classes/ApplicationUser';
import { SubSubNavigationView } from '@app/shared/models/enums/SubSubNavigationView.enum';
import { SubNavigationFilterHelper } from '@app/shared/models/helpers/SubNavigationFilter.helper';
import { BaseMusicFilterSort, MusicFilter, MusicFilterService, MusicSort } from '@app/shared/services/local/music-filter.service';
import { SidebarService } from '@app/shared/services/local/side-bar.service';

@Component({
    selector: 'sound-side-bar-subnavigation',
    templateUrl: './side-bar-subnavigation.component.html',
    styleUrls: ['./side-bar-subnavigation.component.scss'],
    standalone: false
})
export class SideBarSubnavigationComponent extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------
  SubSubNavigationView = SubSubNavigationView;

  activeView: string = '';

  form: UntypedFormGroup;

  subNavigationMusicFilters = SubNavigationFilterHelper.SubNavigationFilters;

  user: ApplicationUser;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private sideBarService: SidebarService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private filterService: MusicFilterService,
    private authenticationService: AuthenticationService
  ) {
    super();

    this.setFormGroup();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.registerChangeTrackers('instrumental', 'vocals');
    this.registerChangeTrackers('vocals', 'instrumental');
    this.registerChangeTrackers('beatFree', 'beat');
    this.registerChangeTrackers('beat', 'beatFree');
    this.registerChangeTrackers('nonPro', 'pro');
    this.registerChangeTrackers('pro', 'nonPro');

    super.addSubscription(
      this.sideBarService.showSubBrowseBar$.subscribe({
        next: (value: [boolean, SubSubNavigationView]) => {
          this.activeView = value[1];
        }
      })
    );

    super.addSubscription(
      this.form.get('isFeatured').valueChanges.subscribe({
        next: (value: any) => {
          this.updateMusicFilter('isFeatured', value);
        }
      })
    );

    super.addSubscription(
      this.form.get('bestseller').valueChanges.subscribe({
        next: (value: any) => {
          this.updateMusicFilter('bestseller', value);
        }
      })
    );


    this.updateSubNavigationMusicFilters(this.filterService.filters);

    super.addSubscription(
      this.filterService.filters$.subscribe({
        next: (filters: BaseMusicFilterSort[]) => {
          this.updateSubNavigationMusicFilters(filters);
        }
      })
    );

    this.user =  this.authenticationService.user;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  setNestedActiveSubSubNavigation(view: SubSubNavigationView): void {
    this.sideBarService.toggleSubBrowseSideBar(view === this.activeView ? null : view);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  private setFormGroup(): void {
    this.form = this.fb.group({
      isFeatured: [false],
      bestseller: [false],
      vocals: [false],
      instrumental: [false],
      beat: [false],
      beatFree: [false],
      pro: [false],
      nonPro: [false]
    });
  }

  private registerChangeTrackers(currentControlName: string, opositeControlName: string): void {
    super.addSubscription(
      this.form.get(currentControlName).valueChanges.subscribe({
        next: (value: boolean) => {
          if (this.form.get(currentControlName).value === true) {

            this.form.get(opositeControlName).setValue(false, { emitEvent: false });
            this.form.get(opositeControlName).setErrors({ invalid: true }, { emitEvent: false });
            this.form.get(currentControlName).setErrors(null);

            this.cdr.detectChanges();

            // Add current filter to the service and remove oposite filter from service
            // Removal must happen first, as otherwise updateSubNavigationfilters will overwrite the
            // values set above
            // i.e pro is true, but non-pro is false => if pro is set to true first, 
            // the non-pro is still set as a filter in the service which will mean that the value of the pro 
            // will be overwritten. In the end two false values would result
            this.updateMusicFilter(opositeControlName, false);
            this.updateMusicFilter(currentControlName, true);
          } else {

            if (this.form.get(opositeControlName).value === false) {
              this.form.get(opositeControlName).setErrors(null);
            }

            this.cdr.detectChanges();

            // Remove current filter from the service
            this.updateMusicFilter(currentControlName, false);
          }
        }
      })
    );
  }


  /**
   * Function for generating and posting the filter/sort to the music filter service
   *
   * @private
   * @param {string} controlName
   * @return {*}  {(MusicFilter)}
   * @memberof SideBarSubnavigationComponent
   */
  private getMusicFilter(controlName: string): MusicFilter {
    const filter: MusicFilter = new MusicFilter();

    const currentMusicFilterValues = this.subNavigationMusicFilters.find(q => q.property === controlName);

    filter.key = currentMusicFilterValues.name;
    filter.title = currentMusicFilterValues.displayName;
    filter.value = currentMusicFilterValues.value;
    filter.type = 'Include';

    return filter;
  }

  
  /**
   * This function updates the values of the filters within this subnavigation when their values do not coincide with the currently present 
   * filter values. This will happen when a user manually removes a filter from the selected filters list, or when the filters are firstly loaded 
   * from the url. In the normal case, when a filter is updated from the subnavigation, this call will still be reached, but no actions should occur
   * 
   * PLEASE NOTE AND DO NOT REMOVE THE EVENT EMITTER AS THERE WOULD BE A LOOP CREATED IMMEDIATELY
   *
   * @private
   * @param {BaseMusicFilterSort[]} filters
   * @memberof SideBarSubnavigationComponent
   */
  private updateSubNavigationMusicFilters(filters: BaseMusicFilterSort[]): void {
    const relevantMusicFilters = filters.filter(q => this.subNavigationMusicFilters.findIndex(p => p.displayName === q.title) !== -1);


    for (const entry of this.subNavigationMusicFilters) {
      const currentMusicFilter = relevantMusicFilters.find(q => q.title === entry.displayName);

      if (this.IsNullOrUndefinedOrEmpty(currentMusicFilter)) {
        if (this.form.get(entry.property).value === true) {
          this.form.get(entry.property).setValue(false, { emitEvent: false });

          if (!this.IsNullOrUndefinedOrEmpty(entry.opositeProperty)) {
            this.form.get(entry.opositeProperty).setErrors(null);
          }
        }
      } else {
        if (this.form.get(entry.property).value === false) {
          this.form.get(entry.property).setValue(true, { emitEvent: false });

          if (!this.IsNullOrUndefinedOrEmpty(entry.opositeProperty)) {
            this.form.get(entry.opositeProperty).setValue(false, { emitEvent: false });
            this.form.get(entry.opositeProperty).setErrors({ invalid: true }, { emitEvent: false });
            this.form.get(entry.property).setErrors(null);
          }
        }
      }
      
      this.cdr.detectChanges();
    }
  }

  private updateMusicFilter(controlName: string, isNew: boolean): void {
    const newMusicFilter = this.getMusicFilter(controlName);

    if (isNew === true) {
      this.filterService.pushOrUpdateFilterOrSort(newMusicFilter);
    } else {
      this.filterService.removeFilterByDisplayName(newMusicFilter.title);
    }
  }
}
