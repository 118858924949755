<!-- SUBMENU ITEMS WITH FURTHER VIEWS -->
<!-- GENRE -->
<sound-navigation-link [displayName]="'Browse.Genre' | translate"
    (onClick)="setNestedActiveSubSubNavigation(SubSubNavigationView.Genre)"
    [navigationLinkClass]="activeView === SubSubNavigationView.Genre ? 'active active--borderless' : ''">
</sound-navigation-link>

<!-- MOOD -->
<sound-navigation-link [displayName]="'Browse.Mood' | translate"
    (onClick)="setNestedActiveSubSubNavigation(SubSubNavigationView.Mood)"
    [navigationLinkClass]="activeView === SubSubNavigationView.Mood ? 'active active--borderless' : ''">
</sound-navigation-link>

<!-- THEMES -->
<sound-navigation-link [displayName]="'Browse.Themes' | translate"
    (onClick)="setNestedActiveSubSubNavigation(SubSubNavigationView.Theme)"
    [navigationLinkClass]="activeView === SubSubNavigationView.Theme ? 'active active--borderless' : ''">
</sound-navigation-link>

<!-- INSTRUMENT -->
<sound-navigation-link [displayName]="'Browse.Instrument' | translate"
    (onClick)="setNestedActiveSubSubNavigation(SubSubNavigationView.Instrument)"
    [navigationLinkClass]="activeView === SubSubNavigationView.Instrument ? 'active active--borderless' : ''">
</sound-navigation-link>

<!-- ARTIST -->
<sound-navigation-link [displayName]="'Browse.Artist' | translate"
    (onClick)="setNestedActiveSubSubNavigation(SubSubNavigationView.Artist)"
    [navigationLinkClass]="activeView === SubSubNavigationView.Artist ? 'active active--borderless' : ''">
</sound-navigation-link>

<!-- Advanced -->
<sound-navigation-link [displayName]="'Browse.Advanced' | translate"
    (onClick)="setNestedActiveSubSubNavigation(SubSubNavigationView.Advanced)"
    [navigationLinkClass]="activeView === SubSubNavigationView.Advanced ? 'active active--borderless' : ''">
</sound-navigation-link>

<mat-divider class="sub-sidebar__divider" style="margin-top: 15px; margin-bottom: 7px; border-top: 1px dashed #3e3a40;"></mat-divider>

<!-- FILTERS -->
<div class="pt-5 sm:pt-4 pl-7">
    <!-- TRENDING -->
    <jad-form-fields-checkbox [control]="form?.get('isFeatured')" [class]="'pb-5'">
        {{'Browse.Trending' | translate}}
    </jad-form-fields-checkbox>
</div>

<mat-divider class="sub-sidebar__divider" style="margin-top: 3px; margin-bottom: 6px; border-top: 1px dashed #3e3a40;"></mat-divider>

<div class="pt-5 sm:pt-4 pl-7">
    <!-- VOCALS -->
    <jad-form-fields-checkbox [control]="form?.get('vocals')" style="padding-bottom: 15px;"
        [formFieldClass]="form.get('vocals').hasError('invalid') ? 'invalid' : ''">
        {{'Browse.Vocals' | translate}}
    </jad-form-fields-checkbox>

    <!-- INSTRUMENTAL -->
    <jad-form-fields-checkbox [control]="form?.get('instrumental')" [class]="'pb-5'"
        [formFieldClass]="form.get('instrumental').hasError('invalid') ? 'invalid' : ''">
        {{'Browse.Instrumental' | translate}}
    </jad-form-fields-checkbox>
</div>

<mat-divider class="sub-sidebar__divider" style="margin-top: 3px; margin-bottom: 6px; border-top: 1px dashed #3e3a40;"></mat-divider>

<div class="pt-5 sm:pt-4 pl-7">
    <!-- BEAT -->
    <jad-form-fields-checkbox [control]="form?.get('beat')" style="padding-bottom: 15px;"
        [formFieldClass]="form.get('beat').hasError('invalid') ? 'invalid' : ''">
        {{'Browse.Beat' | translate}}
    </jad-form-fields-checkbox>

    <!-- BEAT-FREE -->
    <jad-form-fields-checkbox [control]="form?.get('beatFree')" [class]="'pb-5'"
        [formFieldClass]="form.get('beatFree').hasError('invalid') ? 'invalid' : ''">
        {{'Browse.Beat-Free' | translate}}
    </jad-form-fields-checkbox>
</div>

<mat-divider class="sub-sidebar__divider" style="margin-top: 3px; margin-bottom: 6px; border-top: 1px dashed #3e3a40;"></mat-divider>

<div class="pt-5 sm:pt-4 pl-7" *ngIf="!user?.removeProNonProFilter">
    <!-- PRO --> 
    <jad-form-fields-checkbox [control]="form?.get('pro')" style="padding-bottom: 15px;'"
        [formFieldClass]="form.get('pro').hasError('invalid') ? 'invalid' : ''">
        {{'Browse.Pro' | translate}}
    </jad-form-fields-checkbox>

    <!-- NON-PRO -->
    <jad-form-fields-checkbox [control]="form?.get('nonPro')" [class]="'pb-5'"
        [formFieldClass]="form.get('nonPro').hasError('invalid') ? 'invalid' : ''">
        {{'Browse.Non-Pro' | translate}}
    </jad-form-fields-checkbox>
</div>
<mat-divider class="sub-sidebar__divider" style="margin-top: 3px; border-top: 1px dashed #3e3a40;"></mat-divider>
