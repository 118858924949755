import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { __ } from '@app/shared/functions/object.functions';
import { Artist as TEntity } from '@app/shared/models/classes/Artist';
import { ArtistService as TEntityService } from '@app/shared/services/artist.service';
import { Observable } from 'rxjs';

import { IFilterResettable } from '../../entity-filters/base/IFilterResettable';
import { EntitySearcherComponent } from '../base/entity-searcher/entity-searcher.component';

@Component({
    selector: 'sound-entity-searcher-artists',
    templateUrl: './entity-searcher-artists.component.html',
    styleUrls: ['./entity-searcher-artists.component.scss'],
    standalone: false
})
export class EntitySearcherArtistsComponent implements IFilterResettable, OnInit {

  @Input() disabled: boolean = false;

  @Input() required: boolean = false;

  @Input() value: TEntity[] = [];

  @Output() valueChanged: EventEmitter<TEntity> = new EventEmitter<TEntity>();

  getEntities$: (search: string) => Observable<TEntity[]>;

  customOptionClass: string = 'mat-option-artist';

  @Input() placeholder = 'Nach Artists suchen';

  @Input() error: any = null;

  @Input() displaySelectedValue: boolean = true;;

  @Input() label = 'Artist';

  @Input() panelWidth: string = undefined;

  @ViewChild('entitySearcher') entitySearcher: EntitySearcherComponent<TEntity>;

  constructor(private entityService: TEntityService) {
    this.entityService = entityService;
  }

  ngOnInit() {
    this.getEntities$ = (search: string) => this.entityService.getAll(`filter=${search}`, 0, 20, null);
  }

  idSelector = (entity: TEntity) => entity.id;

  labelSelector = (entity: TEntity) => {
    return __.IsNullOrUndefined(entity) ? '' : entity.pseudonym;
  };

  subLabelSelector = (entity: TEntity) => {
    return ``;
  };

  reset(): void {
    this.entitySearcher.resetValue();
  }
}
