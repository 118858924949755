<!--TITLE-->
<div class="uppercase medium" style="color: #e5e5ea">
  <ng-container [ngTemplateOutlet]="title?.template"></ng-container>
</div>

<!-- LINKS -->
<div [class]="IsNullOrUndefinedOrEmpty(listDisplayClass) ? 'flex flex-col flex-wrap' : listDisplayClass">
  <div *ngFor="let link of links;" class="pt-[0.371rem]">
    <a [soundtaxiRouterLink]="'/' + link.route">
      <ng-container [ngTemplateOutlet]="link.content">
      </ng-container>
    </a>
  </div>
</div>