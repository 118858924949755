import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { FileContainer } from '@app/shared/components/files/models/FileContainer';
import { Role } from '@app/shared/models/classes/Role';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UploadDialogComponent, UploadDialogData } from '../upload-dialog/upload-dialog.component';

@Component({
    selector: 'sound-tracks-upload-dropzone',
    templateUrl: './tracks-upload-dropzone.component.html',
    styleUrls: ['./tracks-upload-dropzone.component.scss'],
    standalone: false
})
export class TracksUploadDropzoneComponent extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  isDropped: boolean = false;

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    super();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  openUploadDialogWithErrors(error: any): void {

    if (!this.IsNullOrUndefined(error)) {

      // Open upload dialog
      this.openUploadDialog([], true, error.file);
    }
  }

  openUploadDialog(files: FileContainer[] = [], hasErrors: boolean = false, erroneousFile: FileContainer = null): void {
    this.isDropped = files.length > 0 || !this.IsNullOrUndefinedOrEmpty(erroneousFile) ? true : false;

    if (!this.authenticationService.hasAnyRole([Role.Curator, Role.Administrator]) && this.IsNullOrUndefinedOrEmpty(this.authenticationService?.credentials.user?.artist?.id)) {
      this.toastr.error(this.translateService.instant('Tracks.You have not been registered as an artist yet. Please get in touch with an administrator'));
    } else {
      super.addSubscription(
        this.dialog.open(UploadDialogComponent, {
          data: Object.assign(new UploadDialogData(), {
            artistId: this.authenticationService.credentials.user?.artist?.id ?? null,
            isCurator: this.authenticationService.hasAnyRole([Role.Curator, Role.Administrator]),
            audios: files,
            erroneousFile,
            hasErrors
          })
        }).afterClosed()
          .subscribe({
            next: (result: any) => {
              this.isDropped = false;
            }
          })
      );
    }
  }

}
