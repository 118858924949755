import { Component } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { AppRoute } from '@app/shared/app.route.enum';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { Role } from '@app/shared/models/classes/Role';

/**
 * This component is reponsible for the display of the basic navigation that is visible
 * on all authenticated pages.
 *
 * @export
 * @class NavigationComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'sound-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    standalone: false
})
export class NavigationComponent extends BaseComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  Role = Role;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
  ) {
    super();
  }
}