import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@app/shared';
import { FileDropzoneModule } from '@app/shared/components/files/file-dropzone/file-dropzone.module';
import { UploadDialogModule } from '../upload-dialog/upload-dialog.module';
import { TracksUploadDropzoneComponent } from './tracks-upload-dropzone.component';

@NgModule({
  imports: [
    SharedModule,
    FileDropzoneModule,
    MatIconModule,
    UploadDialogModule
  ],
  declarations: [TracksUploadDropzoneComponent],
  exports: [TracksUploadDropzoneComponent]
})
export class TracksUploadDropzoneModule { }
