import { Component } from '@angular/core';
import { SidebarService } from '@app/shared/services/local/side-bar.service';
import { BaseSideBarComponent } from '../shared/base-side-bar-component';

@Component({
    selector: 'sound-side-bar-actions',
    templateUrl: './side-bar-actions.component.html',
    styleUrls: ['./side-bar-actions.component.scss'],
    standalone: false
})
export class SideBarActionsComponent extends BaseSideBarComponent {
  
  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    protected sideBarService: SidebarService
  ) {
    super(sideBarService);
  }

}
