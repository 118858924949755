import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeRo from '@angular/common/locales/ro';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@app/shared';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { I18nService } from './core/i18n.service';
import { AppRoute } from './shared/app.route.enum';
import { APP_ROUTE, APP_TITLE, BreadcrumbsService } from './shared/components/breadcrumbs/breadcrumbs.service';
import {
  ConfigurableTableDialogModule,
} from './shared/components/configurable-table-dialog/configurable-table-dialog.module';
import { MusicPlayerModule } from './shared/components/music-player/music-player.module';
import { CountryCodeService } from './shared/services/country-code.service';
import { LoadingBarModule } from './shared/services/local/loading-bar/loading-bar.module';
import { MetaService } from './shared/services/local/meta.service';
import { MinuteSecondsPipeModule } from './shared/pipes/minute-seconds.module';
import { MinuteSecondsPipe } from './shared/pipes/minute-seconds.pipe';
// import { register } from 'swiper/element/bundle';

// register();

registerLocaleData(localeDe);
registerLocaleData(localeEn);
registerLocaleData(localeRo);

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

const maskConfig: Partial<IConfig> = {
  validation: true,
};

export function getCurrentLanguage(i18nService: I18nService) {
  i18nService.init(environment.supportedLanguages);
  return i18nService.language;
}

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserAnimationsModule,
        BrowserModule,
        NgxMaskModule.forRoot(maskConfig),
        LoadingBarModule.forRoot(),
        FormsModule,
        CoreModule,
        SharedModule,
        MatProgressSpinnerModule,
        TranslateModule.forRoot(),
        MomentDateModule,
        MatDatepickerModule,
        ConfigurableTableDialogModule,
        MusicPlayerModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        AppRoutingModule // must be imported as the last module as it contains the fallback route
    ], providers: [
        BreadcrumbsService,
        CurrencyPipe,
        MetaService,
        CountryCodeService,
        MinuteSecondsPipe,
        { provide: APP_ROUTE, useValue: AppRoute },
        { provide: APP_TITLE, useValue: 'Soundtaxi' },
        { provide: LOCALE_ID, useFactory: getCurrentLanguage, deps: [I18nService] },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {

  constructor() {
    // http://patorjk.com/software/taag/#p=display&f=Epic&t=Soundtaxi
    console.log(`
 ______  _______           _        ______ _________ _______          _________
/  ___ \\(  ___  )|\\     /|( (    /|(  __  \\\\__   __/(  ___  )|\\     /|\\__   __/
| (   \\/| (   ) || )   ( ||  \\  ( || (  \\  )  ) (   | (   ) |( \\   / )   ) (   
| (____ | |   | || |   | ||   \\ | || |   ) |  | |   | (___) | \\ (_) /    | |   
\\____  )| |   | || |   | || (\\ \\) || |   | |  | |   |  ___  |  ) _ (     | |   
     ) || |   | || |   | || | \\   || |   ) |  | |   | (   ) | / ( ) \\    | |   
/\\___) || (___) || (___) || )  \\  || (__/  )  | |   | )   ( |( /   \\ )___) (___
\\______)(_______)(_______)|/    )_)(______/   )_(   |/     \\||/     \\|\\_______/
    `);
    console.log('Hi! Nice to meet you. Interested in any developer related product, such as our api? Contact us at info@soundtaxi.com!');
    console.log(' ');
  }
}
