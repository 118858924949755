import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { SidebarService } from '@app/shared/services/local/side-bar.service';
import { ActionsModule } from './actions/actions.module';
import { BaseLayoutComponent } from './base-layout.component';
import { ContentFooterModule } from './content-footer/content-footer.module';
import { ContentHeaderModule } from './content-header/content-header.module';
import { NavigationModule } from './navigation/navigation.module';
import { SideBarModule } from './side-bar/side-bar.module';
import { SoundtaxiRouterLinkWithHrefModule } from '@app/shared/directives/router-link/router-link.module';


@NgModule({
    imports: [
        SharedModule,

        ActionsModule,
        NavigationModule,
        ContentHeaderModule,
        SoundtaxiRouterLinkWithHrefModule,
        ContentFooterModule,
        MatIconModule,
        SideBarModule,

        MatSelectModule,

        RouterModule
    ],
    declarations: [
        BaseLayoutComponent
    ],
    providers: [SidebarService]
})
export class BaseLayoutModule { }