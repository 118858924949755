import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';

@Component({
    selector: 'app-partner',
    templateUrl: './partner.component.html',
    styleUrls: ['./partner.component.scss'],
    standalone: false
})
export class PartnerComponent extends BaseComponent {

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
    super();

    this.scrollUp();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // ------------------------------------------------------------------------------------------------------

  scrollUp(): void {
    const content = this.document.getElementById('content');
    content.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}