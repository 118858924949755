import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoute } from '@app/shared/app.route.enum';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { Track, TrackVariation, TrackVariationFile, TrackVariationFileType } from '@app/shared/models/classes/Track';
import { BadgeType } from '@app/shared/models/enums/BadgeType.enum';
import { FavoritesService } from '@app/shared/services/local/favorites.service';
import { MusicPlayerService } from '@app/shared/services/local/music-player.service';
import { MusicPlaylistService } from '@app/shared/services/local/music-playlist.service';
import { Image } from '@app/shared/models/classes/Image';
import { I18nService } from '@app/core/i18n.service';

@Component({
    selector: 'sound-music-player',
    templateUrl: './music-player.component.html',
    styleUrls: ['./music-player.component.scss'],
    standalone: false
})
export class MusicPlayerComponent extends BaseComponent implements OnInit {

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // ------------------------------------------------------------------------------------------------------

  BadgeType = BadgeType;

  isExpanded: boolean = false;

  item: Track;

  currentIndex: number = 0;

  toggleIcon: string = 'arrow_simple_right';

  currentTime: string = '00:00';

  image: string;

  originalVariation: TrackVariation;

  /// -----------------------------------------------------------------------------------------------------
  // @ VIEW CHILD/CHILDREN VARIABLES
  // ------------------------------------------------------------------------------------------------------

  @ViewChild('timer', { static: true }) timer: ElementRef;

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private musicPlayerService: MusicPlayerService,
    private musicPlaylistService: MusicPlaylistService,
    private i18nService: I18nService,
    private favoritesService: FavoritesService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    super();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.isExpanded = this.musicPlayerService.isExpanded;

    // Todo: Get the correct variation to play
    this.item = this.musicPlaylistService.activeTrack;

    this.image = this.getItemImage(this.item?.artist?.images);

    super.addSubscription(
      this.musicPlaylistService
        .activeTrack$
        .subscribe({
          next: (value: { track: Track, index: number }) => {
            this.item = value.track;
            this.originalVariation = this.item.variations?.find(q => q.isOriginal === true);
            this.currentIndex = value.index;

            setTimeout(() => {
              this.musicPlayerService.play(this.item.variations[this.currentIndex]?.id + `undefined`, false);
            }, 100);
            // FIXME:
            // this.musicPlayerService.changeTrack('', this.item);
            this.image = this.getItemImage(this.item?.artist?.images);
          }
        })
    );

    // Track expanded
    super.addSubscription(
      this.musicPlayerService
        .expand$
        .subscribe({
          next: ({ id, expand }) => {
            this.isExpanded = expand;
            this.toggleIcon = this.isExpanded ? 'close_navigation' : 'arrow_simple_right';

            this.cdr.detectChanges();
          }
        })
    );

    // Get current time
    super.addSubscription(
      this.musicPlayerService
        .timeChanges$
        .subscribe({
          next: ({ id, time }) => {
            this.currentTime = TrackVariation.getLength(Math.round(time));

            this.ngZone.runOutsideAngular(() => {
              this.timer.nativeElement.innerHTML = TrackVariation.getLength(Math.min(Math.round(time), this.item?.variations[this.currentIndex].length));
            });

            this.cdr.detectChanges();
          }
        })
    );

    // Track finished playing
    super.addSubscription(
      this.musicPlayerService
        .finish$
        .subscribe({
          next: ({ id, finished }) => {
            if (id === '-music-player') {
              this.musicPlaylistService.playNext();
            }
          }
        })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  toggleMusicPlayer(): void {
    this.isExpanded = !this.isExpanded;
    this.musicPlayerService.toggleMusicPlayer('', this.isExpanded);

    this.toggleIcon = this.isExpanded ? 'close_navigation' : 'arrow_simple_right';
  }

  navigateToTrack(): void {
    this.router.navigateByUrl(this.i18nService.getAbsoluteUrlWithCurrentLanguage(`/${AppRoute.Tracks}/${this.originalVariation?.normalizedName}`));
  }

  navigateToArtist(event: Event): void {
    event.stopPropagation();
    this.router.navigateByUrl(this.i18nService.getAbsoluteUrlWithCurrentLanguage(`/${AppRoute.Artists}/${this.item.artist?.normalizedPseudonym}`));
  }

  getPeaksURL(): string {
    // TODO: GET THE PROPER VARIATION  (USE PLaylist service)
    // TODO NOTE THAT THE PLAYLIST ONLY HANDLES THE SONGS IN THE CURRENT PAGE INCLUDING THEIR VARIATIONS

    const files: TrackVariationFile[] = this.item?.variations[this.currentIndex]?.files || [];
    const fileId: string = files.find(q => q.type === TrackVariationFileType.AudioForm)?.url || '';

    return fileId;
  }

  getSource(): string {
    // TODO: GET THE PROPER VARIATION 
    const files: TrackVariationFile[] = this.item?.variations[this.currentIndex]?.files || [];
    const fileId: string = files.find(q => q.type === TrackVariationFileType.Mp3)?.url || '';

    return fileId;
  }

  getLengthTime(): string {
    return TrackVariation.getLength(this.item?.variations[this.currentIndex]?.length);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  private getItemImage(images: Image[]): string {
    if (this.IsNullOrUndefinedOrEmpty(images) || this.IsNullOrUndefinedOrEmpty(images[0].variations)) {
      return null;
    }

    if (images[0].variations.some(q => q.type === 'Thumbnail') === false) {
      return images[0].variations[0].src;
    }

    return images[0].variations.find(q => q.type === 'Thumbnail').src;
  }
}