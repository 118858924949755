import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { ButtonsMenuModule } from '@app/shared/components/buttons/menu/buttons-menu.module';
import { JadFormFieldsSelectModule } from '@app/shared/components/form-fields/select/form-fields-select.module';
import { ImageModule } from '@app/shared/components/image/image.module';
import { MessagesWarningModule } from '@app/shared/components/messages/messages-warning/messages-warning.module';
import { MusicPlayerVolumeModule } from '@app/shared/components/music-player/volume/music-player-volume.module';
import { SearchModule } from '@app/shared/components/search';
import { SearchBarModule } from '@app/shared/components/search-bar/search-bar.module';
import { TracksUploadDropzoneModule } from '@app/tracks/shared/tracks-upload-dropzone/tracks-upload-dropzone.module';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderComponent } from './content-header.component';
import { NavigationLinkModule } from '../navigation/link/navigation-link.module';

@NgModule({
  imports: [
    CommonModule,

    ImageModule,
    RouterModule,
    MessagesWarningModule,
    TranslateModule,
    MatMenuModule,
    NavigationLinkModule,
    MatSelectModule,
    ButtonsMenuModule,
    MatIconModule,
    MatBadgeModule,
    SearchModule,
    SearchBarModule,
    TracksUploadDropzoneModule,

    JadFormFieldsSelectModule,
    MusicPlayerVolumeModule
  ],
  declarations: [
    ContentHeaderComponent
  ],
  exports: [ContentHeaderComponent]
})
export class ContentHeaderModule { }
