import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LinkListTitleDirective } from './link-list-title/link-list-title.directive';
import { LinkListComponent } from './link-list.component';
import { LinkListLinkModule } from './link/link-list-link.module';
import { SoundtaxiRouterLinkWithHrefModule } from '@app/shared/directives/router-link/router-link.module';

@NgModule({
  imports: [
    CommonModule,
    SoundtaxiRouterLinkWithHrefModule,
    RouterModule
  ],
  exports: [
    LinkListComponent,
    LinkListLinkModule,
    LinkListTitleDirective
  ],
  declarations: [
    LinkListComponent,
    LinkListTitleDirective
  ]
})
export class LinkListModule { }
