<!-- Router link active will not work with dynamic content -->
<a *ngIf="this.IsNullOrUndefinedOrEmpty(this.link)" (click)="redirectToLink()" [matTooltip]="tooltip"
  matTooltipPosition="above" [ngClass]="[
    'navigation-link group flex flex-row items-center', 
    navigationLinkClass,
    justifyClass,
    !IsNullOrUndefinedOrEmpty(displayName) ? 'pl-7': '',
    !IsNullOrUndefinedOrEmpty(link) && IsNullOrUndefinedOrEmpty(fragment) && router.url.includes(link) ? !IsNullOrUndefinedOrEmpty(displayName) ? 'active' : 'active active--borderless' : '',
    iconClass?.includes('logo') ? 'navigation-link--logo' : '']">
  <div class="flex-initial flex" *ngIf="!IsNullOrUndefinedOrEmpty(icon)">
    <mat-icon [svgIcon]="icon" [ngClass]="[iconClass, 'large']"></mat-icon>
  </div>
  <div [ngClass]="{'flex-auto ml-0 uppercase': !IsNullOrUndefinedOrEmpty(displayName)}">
    {{ displayName }}
  </div>
</a>

<a [soundtaxiRouterLink]="'/' + this.link" *ngIf="!this.IsNullOrUndefinedOrEmpty(this.link)" (click)="redirectToLink()" [matTooltip]="tooltip"
  matTooltipPosition="above" [ngClass]="[
    'navigation-link group flex flex-row items-center', 
    navigationLinkClass,
    justifyClass,
    !IsNullOrUndefinedOrEmpty(displayName) ? 'pl-7': '',
    !IsNullOrUndefinedOrEmpty(link) && IsNullOrUndefinedOrEmpty(fragment) && router.url.includes(link) ? !IsNullOrUndefinedOrEmpty(displayName) ? 'active' : 'active active--borderless' : '',
    iconClass?.includes('logo') ? 'navigation-link--logo' : '']">
  <div class="flex-initial flex" *ngIf="!IsNullOrUndefinedOrEmpty(icon)">
    <mat-icon [svgIcon]="icon" [ngClass]="[iconClass, 'large']"></mat-icon>
  </div>
  <div [ngClass]="{'flex-auto ml-0 uppercase': !IsNullOrUndefinedOrEmpty(displayName)}">
    {{ displayName }}
  </div>
</a>