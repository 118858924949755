

export enum SubSubNavigationView {
    Genre = 'Genre',
    Mood = 'Mood',
    Theme = 'Theme',
    Instrument = 'Instrument',
    Artist = 'Artist',
    Advanced = 'Advanced'
}
