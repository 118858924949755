<div class="breadcrumbs">
  <div class="breadcrumb" [class.first]="i === 0" *ngFor="let breadcrumbSegment of breadcrumbSegments; let i = index">
    <ng-container *ngIf="i !== 0">
      <div class="breadcrumb__divider">/</div>
    </ng-container>
    <div class="breadcrumb__link">
      <ng-container *ngIf="IsNullOrUndefinedOrEmpty(breadcrumbSegment.routerLink)">{{
        breadcrumbSegment.title
      }}</ng-container>
      <ng-container *ngIf="!IsNullOrUndefinedOrEmpty(breadcrumbSegment.routerLink)"
        ><a [soundtaxiRouterLink]="breadcrumbSegment.routerLink">{{ breadcrumbSegment.title }}</a></ng-container
      >
    </div>
    <ng-container *ngIf="!IsNullOrUndefinedOrEmpty(breadcrumbSegment.viewName)">
      <div class="breadcrumb__divider">/</div>
      <div class="breadcrumb__link">{{ breadcrumbSegment.viewName }}</div>
    </ng-container>
  </div>
</div>
