import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FilesService } from '@app/shared/services/files.service';
import { LicensesService } from '@app/shared/services/licenses.service';
import { SharedModule } from '@app/shared/shared.module';
import { AddToProjectDialogModule } from '@app/tracks/shared/add-to-project-dialog/add-to-project-dialog.module';
import { ButtonsMenuModule } from '../../buttons/menu/buttons-menu.module';
import { DialogModule } from '../../dialog/dialog.module';
import { DownloadButtonModule } from '../../download-button/download-button.module';
import { MusicPlayerActionsComponent } from './music-player-actions.component';

@NgModule({
  imports: [
    SharedModule,
    MatIconModule,
    DownloadButtonModule,
    ButtonsMenuModule,
    MatTooltipModule,
    DialogModule,
    AddToProjectDialogModule
  ],
  declarations: [MusicPlayerActionsComponent],
  exports: [MusicPlayerActionsComponent],
  providers: 
  [
    FilesService,
    LicensesService
  ]
})
export class MusicPlayerActionsModule { }
