import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubSubNavigationView } from '@app/shared/models/enums/SubSubNavigationView.enum';
import { MusicPlayerService } from '@app/shared/services/local/music-player.service';
import { SidebarService } from '@app/shared/services/local/side-bar.service';

import { BaseSideBarComponent } from './shared/base-side-bar-component';

@Component({
    selector: 'sound-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss'],
    standalone: false
})
export class SideBarComponent extends BaseSideBarComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  showBrowseSidebar: boolean = false;

  subSubNavigationView: SubSubNavigationView;

  isMusicPlayerExpanded: boolean = false;

  isBrowsePage: boolean = false;
  
  hideSideBar: boolean = false;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    protected sideBarService: SidebarService,
    private musicPlayerService: MusicPlayerService,
    private router: Router
  ) {
    super(sideBarService);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    super.addSubscription(
      this.sideBarService.showSubBrowseBar$.subscribe({
        next: (value: [boolean, SubSubNavigationView]) => {
          this.subSubNavigationView = value[1];
        }
      })
    );

    super.addSubscription(
      this.musicPlayerService.expand$.subscribe({
        next: ({ id, expand }) => {
          this.isMusicPlayerExpanded = expand;
        }
      })
    );

    super.addSubscription(
      this.sideBarService.showBrowseSideBar$.subscribe({
        next: (value: boolean) => {
          this.showBrowseSidebar = value;
        }
      })
    );

    super.addSubscription(
      this.sideBarService.isBrowsePage$.subscribe({
        next: (value: boolean) => {
          this.isBrowsePage = value;
        }
      })
    );

    super.addSubscription(
      this.sideBarService.hideSideBar$.subscribe({
        next: (value: boolean) => {
          this.hideSideBar = value;
        }
      })
    );
  }

  setNestedActiveSubSubNavigation(view: SubSubNavigationView): void {
    this.sideBarService.toggleSubBrowseSideBar(view);
  }

  toggleBrowseSidebar(value: boolean): void {
    this.showBrowseSidebar = value;
    this.sideBarService.toggleBrowseSideBar(value);
  }

}
