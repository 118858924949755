import { Component, Input } from '@angular/core';
import { SubSubNavigationView } from '@app/shared/models/enums/SubSubNavigationView.enum';
import { SidebarService } from '@app/shared/services/local/side-bar.service';

@Component({
    selector: 'sound-side-bar-subsubnavigation',
    templateUrl: './side-bar-subsubnavigation.component.html',
    styleUrls: ['./side-bar-subsubnavigation.component.scss'],
    standalone: false
})
export class SideBarSubsubnavigationComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------
  SubSubNavigationView = SubSubNavigationView;

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() view: SubSubNavigationView;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private sideBarService: SidebarService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  setNestedActiveSubSubNavigation(view: SubSubNavigationView): void {
    this.sideBarService.toggleSubBrowseSideBar(view);
  }
}