<!-- FOOTER -->
<div class="footer flex flex-row justify-between items-start">

  <!-- CONTACT -->
  <div class="w-56">

    <!-- ICON -->
    <mat-icon [ngClass]="['large', 'extended-logo--large']" [svgIcon]="'soundtaxi_extended_large'"></mat-icon>

    <!-- SOCIAL -->
    <div class="footer__social flex flex-wrap">
      <a href="https://www.youtube.com/@soundtaximusic" target="_blank"><mat-icon [ngClass]="['large']" [svgIcon]="'youtube'"></mat-icon></a>
      <a href="https://www.instagram.com/soundtaximusic/" target="_blank"><mat-icon [ngClass]="['large']" [svgIcon]="'instagram'"></mat-icon></a>
      <a href="https://www.linkedin.com/company/soundtaxi/" target="_blank"><mat-icon [ngClass]="['large']" [svgIcon]="'linkedin'"></mat-icon></a>
      <a href="https://www.facebook.com/soundtaxi" target="_blank"><mat-icon [ngClass]="['large']" [svgIcon]="'facebook'"></mat-icon></a>
    </div>

    <div class="footer__info mt-2">

      <!-- E-MAIL -->
      <div>
        <a href="mailto:info@soundtaxi.com" >info&#64;soundtaxi.com</a>
      </div>

      <!-- PHONE -->
      <div>
        <a href="tel:+49 (0) 711 217 - 202 291">+49 (0) 711 217 - 202 290</a><br><br>
      </div>
    </div>

  </div>

  <!-- COMPANY -->
  <div class="footer__company w-36">
    <sound-link-list>
      <ng-template soundLinkListTitle>
        {{ 'Footer.Company' | translate }}
      </ng-template>
      <sound-link-list-link linkName="{{ 'Footer.Legal Notice' | translate }}" [route]="'imprint'">
      </sound-link-list-link>
      <sound-link-list-link linkName="{{ 'Footer.Terms of Use' | translate }}" [route]="'terms-of-use'">
      </sound-link-list-link>
      <sound-link-list-link linkName="{{ 'Footer.Privacy Policy' | translate }}" [route]="'privacy-policy'">
      </sound-link-list-link>
      <sound-link-list-link linkName="{{ 'Footer.Right to Cancel' | translate }}" [route]="'right-to-cancel'">
      </sound-link-list-link>
     <!-- <sound-link-list-link linkName="{{ 'Footer.License Terms' | translate }}" [route]="'license-terms'">
      </sound-link-list-link> -->
    </sound-link-list>
  </div>

  <!-- INFO -->
  <div class="footer__info w-36">
    <sound-link-list>
      <ng-template soundLinkListTitle>
        {{ 'Footer.Info' | translate }}
      </ng-template>
      <sound-link-list-link linkName="{{ 'Footer.Licenses' | translate }}" [route]="'licenses'">
      </sound-link-list-link>
      <sound-link-list-link linkName="{{ 'Footer.Royalty free music' | translate }}" [route]="'royalty-free-music'">
      </sound-link-list-link>
      <sound-link-list-link linkName="{{ 'Footer.Dolby Atmos' | translate }}" [route]="'dolby-atmos'">
      </sound-link-list-link>
      <sound-link-list-link linkName="{{ 'Footer.Genres' | translate }}" [route]="'genres'">
      </sound-link-list-link>
    </sound-link-list>
  </div>

  <!-- HELP & SUPPORT -->
  <div class="footer__help w-36">
    <sound-link-list> 
      <ng-template soundLinkListTitle>
        {{ 'Footer.Help & Support' | translate }}
      </ng-template>
      <sound-link-list-link linkName="{{ 'Footer.Contact us' | translate }}" [route]="'contact-us'">
      </sound-link-list-link>
      <sound-link-list-link linkName="{{ 'Footer.Support' | translate }}" [route]="'help'">
      </sound-link-list-link>
      <sound-link-list-link linkName="{{ 'Footer.Artist Enquiry' | translate }}" [route]="'artist-enquiry'">
      </sound-link-list-link>
      <sound-link-list-link linkName="{{ 'Footer.AI Search' | translate }}" [route]="'ai-powered'">
      </sound-link-list-link>
     <!--  <sound-link-list-link linkName="{{ 'Footer.Free Royalty free music' | translate }}" [route]="'free-royalty-free-music'">
      </sound-link-list-link>-->
    </sound-link-list>
  </div>
</div>