<!-- CONTAINER -->
<div class="mt-24 md:mt-48 flex flex-col items-start custom-container-width">

  <!-- HEADER -->
  <h1 class="terms-of-use__header mb-8">
    <strong>
      {{ 'Terms Of Use.Terms Of Use' | translate }}
    </strong>
  </h1>

  <!-- CONTENT -->
  <div class="mb-16 md:mb-40 text-left custom-max-width">

    <div class="html-content" [innerHTML]="'Terms Of Use.Content' | translate | safe: 'html'">
    </div>

  </div>

  <!-- BUTTON -->
  <div class="terms-of-use__button text-center mb-8 flex flex-row custom-max-width">
    <sound-button-default (onClick)="scrollUp()" [icon]="'arrow_simple_right'" [order]="'icon-last'" [displayText]="'Terms Of Use.Scroll Up' | translate">
    </sound-button-default>
  </div>

</div>