import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { LinkListModule } from '@app/shared/components/link-list/link-list.module';
import { TranslateModule } from '@ngx-translate/core';
import { ContentFooterComponent } from './content-footer.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatIconModule,
    LinkListModule
  ],
  exports: [ContentFooterComponent],
  declarations: [ContentFooterComponent]
})
export class ContentFooterModule { }
