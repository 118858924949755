import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MusicPlayerService } from '@app/shared/services/local/music-player.service';
import { MusicPlaylistService } from '@app/shared/services/local/music-playlist.service';
import { PlaylistService } from '@app/shared/services/playlist.service';
import { SharedModule } from '@app/shared/shared.module';
import { BadgeModule } from '../badge/badge.module';
import { ButtonsDefaultModule } from '../buttons/default/buttons-default.module';
import { ButtonsMenuModule } from '../buttons/menu/buttons-menu.module';
import { DownloadButtonModule } from '../download-button/download-button.module';
import { ImageModule } from '../image/image.module';
import { WaveformModule } from '../waveform/waveform.module';
import { MusicPlayerActionsModule } from './actions/music-player-actions.module';
import { MusicPlayerControlsModule } from './controls/music-player-controls.module';
import { MusicPlayerComponent } from './music-player.component';
import { MusicPlayerVolumeModule } from './volume/music-player-volume.module';
import { OrganisationsService } from '@app/shared/services/organisations.service';
import { SoundtaxiRouterLinkWithHrefModule } from '@app/shared/directives/router-link/router-link.module';

@NgModule({
  imports: [
    SharedModule,
    ButtonsDefaultModule,
    MatIconModule,
    WaveformModule,
    MatTooltipModule,
    ImageModule,
    SoundtaxiRouterLinkWithHrefModule,
    BadgeModule,
    DownloadButtonModule,
    ButtonsMenuModule,
    MusicPlayerActionsModule,
    MusicPlayerVolumeModule,
    MusicPlayerControlsModule
  ],
  declarations: [MusicPlayerComponent],
  exports: [MusicPlayerComponent],
  providers: [MusicPlayerService, MusicPlaylistService, OrganisationsService]
})
export class MusicPlayerModule { }
