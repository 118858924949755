<!-- ACTIONS -->
<div [ngClass]="{'pt-0 pb-5 align-center': true, 'pl-7': isExpanded, 'justify-center': !isExpanded}">

    <!-- EXPAND -->
    <ng-container *ngIf="isExpanded; else collapsed;">
        <sound-navigation-link iconClass="enabled" icon="arrow_left" justifyPosition="start" (onClick)="toggleSideBar()">
        </sound-navigation-link>
    </ng-container>
    
    <!-- COLLAPSED -->
    <ng-template #collapsed>
        <sound-navigation-link iconClass="enabled" icon="arrow_right" (onClick)="toggleSideBar()" >
        </sound-navigation-link>
    </ng-template>
    
</div>