import { Component, OnInit } from '@angular/core';

import { BreadcrumbSegment, BreadcrumbsService } from './breadcrumbs.service';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { __ } from '@app/shared/functions/object.functions';

/**
 * The breadcrumb component displays breadcrumbs by subscribing to the urlSegments of the active route and
 * to breadcrumbSegments of the breadcrumb service. The routerLinks of the breadcrumbs will be build and
 * displayed with a translateable title.
 */
@Component({
    selector: 'sound-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    standalone: false
})
export class BreadcrumbsComponent extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  breadcrumbSegments: BreadcrumbSegment[] = [];

  IsNullOrUndefinedOrEmpty = __.IsNullOrUndefinedOrEmpty;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private breadcrumbService: BreadcrumbsService
  ) {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.breadcrumbSegments = this.breadcrumbService.getCurrentBreadcrumbs();

    super.addSubscription(
      this.breadcrumbService.breadcrumbSegments$.subscribe((breadcrumbSegments: BreadcrumbSegment[]) => {
        this.breadcrumbSegments = breadcrumbSegments;
      })
    );
  }
}
