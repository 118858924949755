<ng-container *ngIf="isCreatorOrCuratorRoute === false">

    <!-- HOME -->
    <sound-navigation-link link="home" icon="home" [displayName]="getNameOrNull('Home.Home') | translate"
        [isLoading]="isLoadingWaveform">
    </sound-navigation-link>

    <!-- BROWSE -->
    <sound-navigation-link link="browse" icon="browse" [displayName]="getNameOrNull('Home.Browse') | translate"
        [isLoading]="isLoadingWaveform">
    </sound-navigation-link>

    <!-- DISCOVER -->
    <sound-navigation-link link="discover" icon="discover" [displayName]="getNameOrNull('Home.Discover') | translate"
        [isLoading]="isLoadingWaveform">
    </sound-navigation-link>


    <!-- MY PROJECTS -->
    <sound-navigation-link link="my-projects" icon="my_projects" [isLoading]="isLoadingWaveform"
        [displayName]="getNameOrNull('Home.My Projects') | translate">
    </sound-navigation-link>


    <!-- FAVOURITES -->
    <sound-navigation-link link="favourites" icon="favorite" [isLoading]="isLoadingWaveform"
        [displayName]="getNameOrNull('Home.Favourites') | translate">
    </sound-navigation-link>


    <!-- HISTORY -->
    <sound-navigation-link link="history" icon="history" [isLoading]="isLoadingWaveform"
        [displayName]="getNameOrNull('Home.History') | translate">
    </sound-navigation-link>

</ng-container>

<!-- CREATOR & CURATOR -->
<ng-container *ngIf="isCreatorOrCuratorRoute === true">

    <!-- UPLOADS -->
    <sound-navigation-link [isLoading]="isLoadingWaveform" (onClick)="scrollToFragment($event)" [fragment]="'uploads'"
        icon="upload" [displayName]="getNameOrNull('Creator.Uploads') | translate">
    </sound-navigation-link>

    <!-- MY SONGS -->
    <sound-navigation-link [isLoading]="isLoadingWaveform" (onClick)="scrollToFragment($event)" [fragment]="'my-songs'"
        icon="my_songs" [displayName]="getNameOrNull('Creator.My songs') | translate">
    </sound-navigation-link>

    <!-- MESSAGES -->
    <sound-navigation-link [isLoading]="isLoadingWaveform" (onClick)="scrollToFragment($event)" [fragment]="'messages'"
        icon="messenger" [displayName]="getNameOrNull('Creator.Messages') | translate">
    </sound-navigation-link>

</ng-container>