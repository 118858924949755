import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'sound-cookie-dialog',
    templateUrl: './cookie-dialog.component.html',
    styleUrls: ['./cookie-dialog.component.scss'],
    standalone: false
})
export class CookieDialogComponent {

  static cookieKey = 'cookies_zg274g38hf9o4f';

  mode: 'Intro' | 'Manage' = 'Intro';

  necessaryFormControl: UntypedFormControl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CookieDialogComponent>,
  ) {
    this.necessaryFormControl = new UntypedFormControl({ disabled: true, value: true });
  }

  accept(): void {
    // TODO: WHEN ANALYTICS ADD MORE CONTROLS + handle all || necessary
    localStorage.setItem(
      CookieDialogComponent.cookieKey,
      JSON.stringify(
        'Necessary'
      )
    );

    this.dialogRef.close('Necessary');
  }

  save(): void {
    localStorage.setItem(
      CookieDialogComponent.cookieKey,
      JSON.stringify(
        'Necessary'
      )
    );

    this.dialogRef.close('Necessary');
  }
}
