<!-- STICKY FOOTER -->
<!-- TODO: MOBILE RESPONSIVENESS -->
<div class="sticky-footer flex md:hidden" *ngIf="isInput === false">

  <!-- HOME -->
  <a class="sticky-footer__item" [ngClass]="{ 'sticky-footer__item--active': router.url.includes('home') }"  soundtaxiRouterLink="/home">
    <mat-icon class="large" [svgIcon]="'soundtaxi_logo'"></mat-icon>
  </a>

  <!-- BROWSE -->
  <a class="sticky-footer__item" [ngClass]="{ 'sticky-footer__item--active': router.url.includes('browse') }" soundtaxiRouterLink="/browse">
    <mat-icon class="large" [svgIcon]="'browse'"></mat-icon>
  </a>

  <!-- DISCOVER -->
  <a class="sticky-footer__item" [ngClass]="{ 'sticky-footer__item--active': router.url.includes('discover') }" soundtaxiRouterLink="/discover">
    <mat-icon class="large" [svgIcon]="'discover'"></mat-icon>
  </a>

  <!-- FAVOURITES -->
  <a class="sticky-footer__item" [ngClass]="{ 'sticky-footer__item--active': router.url.includes('favourites') }" [soundtaxiRouterLink]="'/favourites'">
    <mat-icon class="large" [svgIcon]="'favorite'"></mat-icon>
  </a>

</div>

<div class="main-container flex flex-row min-h-screen pb-24 md:pb-0" style="max-width: 100vw">

  <!-- SIDE BAR -->
  <sound-side-bar [ngClass]="{'side-bar': hideSideBar === true }" class="side-bar"></sound-side-bar>

  <div id="header" class="flex-auto w-auto">

    <!-- HEADER -->
    <div [ngClass]="{'header': true, 'header__sidebar': sideBarExpanded && !subSideBarExpanded, 
    'header__subsidebar': subSideBarExpanded && !sideBarExpanded && !subSubSideBarIsExpanded,
    'header__side-and-subsidebar': subSideBarExpanded && sideBarExpanded,
    'header__sub-and-subsubsidebar': subSideBarExpanded && subSubSideBarIsExpanded,
    'header--visible': showTopBar, 'header--hidden': showTopBar === false, 
    'header--barred': currentScrollPosition >= 100,
    'header--non-barred': currentScrollPosition < 100}">
      <sound-content-header></sound-content-header>
    </div>

    <!-- CONTENT -->
    <div id="content" [ngClass]="{ 'content flex flex-col': true, 'content--sidebar': sideBarExpanded && !subSideBarExpanded, 
      'content--subsidebar': subSideBarExpanded && !sideBarExpanded && !subSubSideBarIsExpanded,
      'content--side-and-subsidebar': subSideBarExpanded && sideBarExpanded,
      'content--sub-and-subsubsidebar': subSideBarExpanded && subSubSideBarIsExpanded,
      'content--hidesidebar': hideSideBar === true }">
      <ng-container *ngIf="isForbidden === true">
        <h3 class="pt-10 mt-24">{{'General.You do not have access to this item' | translate }}</h3>
        <p>{{'General.In most cases, the item you wanted to access requires permissions that you do not have.'
          | translate }}<br>
          {{
          'General.If this is a mistake, please contact your company administrator to ask for the required permissions.'
          | translate }}
        </p>
      </ng-container>
      <ng-container *ngIf="notFound === true">
        <h3 class="pt-10 mt-24">{{'General.The item you were looking for could not be found' | translate }}</h3>
        <p>{{'General.In most cases, the item you wanted to access does not exist or has been deleted.' | translate }}
        </p>
      </ng-container>

      <ng-container *ngIf="isForbidden === false && notFound === false">
        <div class="flex-grow">
          <router-outlet></router-outlet>
        </div>

        <!-- FOOTER -->
        <div class="footer -mx-20 px-25 pb-safe-offset-20 md:px-30 md:py-10 md:pb-safe-offset-10 bg-primary-dark-purple"
          [ngClass]="{'': true, 
          'md:px-15': (subSideBarExpanded && sideBarExpanded) || (subSideBarExpanded && subSubSideBarIsExpanded),
          'md:px-30': !(sideBarExpanded && subSideBarExpanded && subSubSideBarIsExpanded) || (sideBarExpanded && !subSideBarExpanded) || (subSideBarExpanded && !sideBarExpanded && !subSubSideBarIsExpanded) }">
          <sound-content-footer></sound-content-footer>
        </div>

      </ng-container>
    </div>
  </div>

</div>