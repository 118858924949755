import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ButtonsDefaultModule } from '@app/shared/components/buttons/default/buttons-default.module';
import { DialogModule } from '@app/shared/components/dialog/dialog.module';
import { EntitySearcherArtistsModule } from '@app/shared/components/entity/entitity-searcher-artists/entity-searcher-artists.module';
import { FileDropzoneComponent } from '@app/shared/components/files/file-dropzone/file-dropzone.component';
import { FileDropzoneModule } from '@app/shared/components/files/file-dropzone/file-dropzone.module';
import { JadFormFieldsCheckboxModule } from '@app/shared/components/form-fields/checkbox/form-fields-checkbox.module';
import { LoadingButtonModule } from '@app/shared/components/loading-button/loading-button.module';
import { LoadingBarModule } from '@app/shared/services/local/loading-bar/loading-bar.module';
import { TasksService } from '@app/shared/services/tasks.service';
import { TracksService } from '@app/shared/services/tracks.service';
import { TranslateModule } from '@ngx-translate/core';
import { UploadDialogComponent } from './upload-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatPaginatorModule,
    MatIconModule,
    DialogModule,
    MatDialogModule,
    LoadingBarModule,
    ButtonsDefaultModule,
    LoadingButtonModule,
    JadFormFieldsCheckboxModule,
    FileDropzoneModule,
    EntitySearcherArtistsModule
  ],
  declarations: [UploadDialogComponent],
  exports: [UploadDialogComponent],
  providers: [TracksService, TasksService],
})
export class UploadDialogModule { }
