<!-- CONTAINER -->
<div class="mt-24 md:mt-48 custom-container-width contact-us__container">

  <!-- HEADER -->
  <h1 class="contact-us__header mb-8">
    <strong>
      {{ 'Contact us.Contact us' | translate }}
    </strong>
  </h1>

  <!-- CONTENT -->
  <div class="mb-16 md:mb-40 custom-max-width">
    <div class="html-content" [innerHTML]="'Contact us.Content' | translate | safe: 'html'">
    </div>
  </div>

  <!-- BUTTON -->
  <div class="contact-us__button text-center mb-8">
    <sound-button-default (onClick)="scrollUp()" [icon]="'arrow_simple_right'" [order]="'icon-last'" [displayText]="'Contact us.Scroll Up' | translate">
    </sound-button-default>
  </div>

</div>
