import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { MetaService } from '@app/shared/services/local/meta.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'sound-data-protection',
    templateUrl: './data-protection.component.html',
    styleUrls: ['./data-protection.component.scss'],
    standalone: false
})
export class DataProtectionComponent extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private metaService: MetaService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.metaService.addDefaults({
      title: this.translate.instant('SEO.Data protection.Title'),
      description: this.translate.instant('SEO.Data protection.Description'),
      keywords: this.translate.instant('SEO.Data protection.Keywords')
    });
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // ------------------------------------------------------------------------------------------------------

  scrollUp(): void {
    const content = this.document.getElementById('content');
    content.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

}
