import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { SharedModule } from '@app/shared';
import { JadFormFieldsCheckboxModule } from '@app/shared/components/form-fields/checkbox/form-fields-checkbox.module';
import { NavigationLinkModule } from '../../navigation/link/navigation-link.module';
import { SideBarSubnavigationComponent } from './side-bar-subnavigation.component';

@NgModule({
  imports: [
    SharedModule,
    NavigationLinkModule,
    JadFormFieldsCheckboxModule,
    MatDividerModule
  ],
  declarations: [SideBarSubnavigationComponent],
  exports: [SideBarSubnavigationComponent],
})
export class SideBarSubnavigationModule { }
