import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '@app/shared/shared.module';

import { EntitySearcherRowModule } from '../entity-searcher-row/entity-searcher-row.module';
import { EntitySearcherComponent } from './entity-searcher.component';

@NgModule({
  imports: [SharedModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, MatAutocompleteModule, EntitySearcherRowModule],
  declarations: [EntitySearcherComponent],
  exports: [EntitySearcherComponent, EntitySearcherRowModule]
})
export class EntitySearcherModule { }
