<div class="bg-primary-no-black md:bg-transparent mt-0 px-6 content-header flex justify-between items-center">

  <!-- HEADER START -->
  <div class="hidden md:flex justify-center items-center flex-wrap"
    [ngClass]="{'header-start--search': isCuratorOrCreatorRoute === false }">

    <!-- LOGO -->

    <!-- UPLOAD -->
    <sound-tracks-upload-dropzone *ngIf="isCuratorOrCreatorRoute === true" style="z-index: 3;" class="ml-4">
    </sound-tracks-upload-dropzone>

  </div>

  <!-- HEADER END -->
  <div class="header-end">

    <!-- VOLUME SLIDER -->
    <div class="hidden md:block md:mr-4 md:ml-4 lg:mr-12" style="z-index: 3;">
      <sound-music-player-volume></sound-music-player-volume>
    </div>

    <div class="flex w-full md:w-auto px-0 ">

      <div class="flex items-center md:hidden flex-auto">
        <sound-navigation-link class="link" link="home" icon="soundtaxi_extended_large"
          iconClass="logo mobile extended-logo--small">
        </sound-navigation-link>
      </div>

      <!-- NAVIGATION -->
      <sound-buttons-menu [sizeClass]="'medium'" [colorClass]="'transparent'" style="z-index: 3;">
        <ng-template soundButtonsMenuLabel>
          <mat-icon [ngClass]="['large']" [svgIcon]="'candy'"></mat-icon>
        </ng-template>

        <!-- LICENSES-->
        <sound-buttons-menu-action
          (onClick)="router.navigate([i18nService.getAbsoluteUrlWithCurrentLanguage('/licenses')])"
          [order]="'icon-last'" [iconClass]="'menu'" displayText="{{ 'Navigation.Licenses' | translate }}"
          colorClass="default" sizeClass="large" icon="info">
        </sound-buttons-menu-action>

        <!-- HELP SUPPORT -->
        <sound-buttons-menu-action (onClick)="router.navigate([i18nService.getAbsoluteUrlWithCurrentLanguage('/help')])"
          [order]="'icon-last'" [iconClass]="'menu'" displayText="{{ 'Navigation.Help / Support' | translate }}"
          colorClass="default" sizeClass="large" icon="help">
        </sound-buttons-menu-action>

        <!-- WORK -->
        <sound-buttons-menu-action (onClick)="router.navigate([i18nService.getAbsoluteUrlWithCurrentLanguage('/work')])"
          [order]="'icon-last'" [iconClass]="'menu'" displayText="{{ 'Navigation.Work' | translate }}"
          colorClass="default" sizeClass="large" icon="work">
        </sound-buttons-menu-action>

        <!-- SHARE -->
        <!-- <sound-buttons-menu-action (onClick)="setLanguage($event)" [order]="'icon-last'" [iconClass]="'menu'"
        [displayText]="'Share'" colorClass="default" sizeClass="large" icon="share">
      </sound-buttons-menu-action> -->
      </sound-buttons-menu>

      <!-- MOBILE UPLOAD -->

      <!-- LANGUAGE -->
      <sound-buttons-menu [sizeClass]="'medium'" [colorClass]="'transparent'" style="z-index: 3;">
        <ng-template soundButtonsMenuLabel>
          <mat-icon [ngClass]="['large']" [svgIcon]="'language'"></mat-icon>
        </ng-template>
        <ng-container *ngFor="let language of Languages">
          <sound-buttons-menu-action (onClick)="setLanguage(language.value)" [order]="'icon-last'"
            [displayText]="language.displayName" colorClass="default" sizeClass="large" icon="arrow_left">
          </sound-buttons-menu-action>
        </ng-container>
      </sound-buttons-menu>

      <!-- ACCOUNT -->
      <sound-buttons-menu [sizeClass]="'medium'" [colorClass]="'transparent'" style="z-index: 3;">
        <ng-template soundButtonsMenuLabel>
          <mat-icon [ngClass]="['large']" [svgIcon]="'user'"></mat-icon>
        </ng-template>

        <!-- LOGIN -->
        <sound-buttons-menu-action *ngIf="authenticationService.isAuthenticated() === false"
          (onClick)="router.navigate([i18nService.getAbsoluteUrlWithCurrentLanguage('/login')])" [order]="'icon-last'"
          [iconClass]="'menu'" displayText="{{ 'Navigation.Login' | translate }}" colorClass="default" sizeClass="large"
          icon="sign_in">
        </sound-buttons-menu-action>

        <!-- LOGOUT -->
        <sound-buttons-menu-action *ngIf="authenticationService.isAuthenticated() === true" (onClick)="logout()"
          [order]="'icon-last'" [iconClass]="'menu'" displayText="{{ 'Navigation.Logout' | translate }}"
          colorClass="default" sizeClass="large" icon="logout">
        </sound-buttons-menu-action>

        <!-- MY ACCOUNT -->
        <sound-buttons-menu-action *ngIf="authenticationService.isAuthenticated() === true"
          (onClick)="router.navigate([i18nService.getAbsoluteUrlWithCurrentLanguage('/my-account')])"
          [order]="'icon-last'" [iconClass]="'menu'" displayText="{{ 'Navigation.My Account' | translate }}"
          colorClass="default" sizeClass="large" icon="user">
        </sound-buttons-menu-action>

        <!-- CURATOR DASHBOARD -->
        <sound-buttons-menu-action
          *ngIf="authenticationService.isAuthenticated() === true && authenticationService.hasAnyRole(['Curator', 'Administrator', 'CompanyAdministrator'])"
          (onClick)="router.navigate(['/curator'])" [order]="'icon-last'" [iconClass]="'menu'"
          displayText="{{ 'Navigation.Curator Dashboard' | translate }}" colorClass="default" sizeClass="large"
          icon="soundtaxi_logo">
        </sound-buttons-menu-action>

        <!-- CREATOR DASHBOARD -->
        <sound-buttons-menu-action
          *ngIf="authenticationService.isAuthenticated() === true && authenticationService.hasAnyRole(['Creator', 'Administrator', 'CompanyAdministrator'])"
          (onClick)="router.navigate(['/creator'])" [order]="'icon-last'" [iconClass]="'menu'"
          displayText="{{ 'Navigation.Creator Dashboard' | translate }}" colorClass="default" sizeClass="large"
          icon="user">
        </sound-buttons-menu-action>

        <!-- ADMIN -->
        <sound-buttons-menu-action
          *ngIf="authenticationService.isAuthenticated() === true && authenticationService.hasAnyRole(['Administrator', 'CompanyAdministrator', 'Curator'])"
          (onClick)="router.navigate(['/admin'])" [order]="'icon-last'" [iconClass]="'menu'"
          displayText="{{ 'Navigation.Admin Dashboard' | translate }}" colorClass="default" sizeClass="large"
          icon="work">
        </sound-buttons-menu-action>
      </sound-buttons-menu>

      <!-- CART -->
      <div class="flex">
        <mat-icon class="cart" *ngIf="isCuratorOrCreatorRoute === false"
          [ngClass]="{ 'cursor-pointer': true, 'large': true, 'check': basketIsActive }" [svgIcon]="'shopping_cart'"
          style="z-index: 3;"
          (click)="router.navigate([i18nService.getAbsoluteUrlWithCurrentLanguage('/checkout')])"></mat-icon>
        @if (basketAmount !== 0) {
        <span
          class="inline-flex items-center justify-center -mt-2 -ml-5 w-[1.563rem] h-[1.563rem] z-10 bg-primary-green-50 text-[#2a262b] font-medium rounded-full text-[11px]">
          {{basketAmount}}
        </span>
        }
      </div>

      <!-- CART -->
      <!-- <mat-icon class="cart" *ngIf="isCuratorOrCreatorRoute === false" [matBadge]="basketAmount !== 0 ? basketAmount : null"
      [matBadgeOverlap]="false" [matBadgeDisabled]="basketAmount === 0" [matBadgeHidden]="basketAmount === 0"
      [matBadgeSize]="'large'"
      [ngClass]="{ 'cursor-pointer': true, 'large': true, 'check': basketIsActive }" [svgIcon]="'shopping_cart'"
      style="z-index: 3;" (click)="router.navigate([i18nService.getAbsoluteUrlWithCurrentLanguage('/checkout')])"></mat-icon> -->

    </div>
  </div>

</div>