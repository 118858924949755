<div class="sub-sub-navigation__container">
    <div class="pr-4 pl-7 sub-sub-navigation__list overflow-y-auto pb-10 pt-9 md:pb-7 md:pt-8" #subSubNavigationList>
        <div *ngFor="let item of itemsToDisplay">

            <!-- ITEMS -->
            <div class="flex flex-row items-center place-content-between navigation-filter item">
                <ng-container *ngIf="item.subValues?.length > 0">
                    <mat-icon [svgIcon]="'chevron_right'" [ngClass]="{'drop-down': item.isExpanded, 'expander': true, 'active': isIncluded(item), 'sub-active': hasSubItemIncluded(item)}"
                        (click)="item.isExpanded = !item.isExpanded"></mat-icon>
                </ng-container>
                <a [ngClass]="{'active': isIncluded(item), 'sub-active': hasSubItemIncluded(item), 'flex-auto py-2': true}" (click)="toggleFilterInclude(item)">
                    {{ item?.displayName }}
                </a>
                <ng-container *ngIf="canExclude">
                    <mat-icon [svgIcon]="'exclude'" [ngClass]="{'exclude': true, 'active': isExcluded(item)}"
                        (click)="toggleFilterExclude(item)"></mat-icon>
                </ng-container>
            </div>

            <!-- SUBITEMS -->
            <ng-container *ngIf="item.isExpanded">
                <div *ngFor="let subItem of item.subValues" class="pl-4 flex flex-row items-center place-content-between navigation-filter">
                    <a [ngClass]="{'active': isIncluded(subItem), 'flex-auto': true}" (click)="toggleFilterInclude(subItem, item)">
                        {{ subItem?.displayName }}
                    </a>
                    <ng-container *ngIf="canExclude">
                        <mat-icon [svgIcon]="'exclude'" [ngClass]="{'exclude': true, 'active': isExcluded(subItem)}"
                            (click)="toggleFilterExclude(subItem)"></mat-icon>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>

    <!-- ARTIST ALPHABET -->
    <ng-container *ngIf="type === SubSubNavigationView.Artist">
        <div class="alphabet-filter-switch">
            <div *ngFor="let letter of alphabet" class="letter mr-1">
                <sound-button-default [displayText]="letter"
                    [colorClass]="currentLetter === letter ? 'purple' : 'default'" [sizeClass]="'small'"
                    (onClick)="switchStartLetterFilterValue(letter)"></sound-button-default>
            </div>
        </div>
    </ng-container>
</div>