import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'sound-link-list-link',
    templateUrl: './link-list-link.component.html',
    styleUrls: ['./link-list-link.component.scss'],
    standalone: false
})
export class LinkListLinkComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() route: string;

  @Input() linkName: string;

  // -----------------------------------------------------------------------------------------------------
  // @ VIEW CHILD & VIEW CHILDREN VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @ViewChild(TemplateRef, { static: true }) content: TemplateRef<any>;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor() { }

}
