<div class="form-field-entity-searcher-container mb-3">

  <!-- LABEL -->
  <div class="form-field-entity-searcher-container__label text-xs text-white mb-1"
    *ngIf="!IsNullOrUndefinedOrEmpty(label) || !IsNullOrUndefinedOrEmpty(displayName)">
    {{ !IsNullOrUndefinedOrEmpty(label) ? label : displayName }} {{ required === true ? '*' : '' }}
  </div> <!-- /form-field-entity-searcher-container__label -->

  <!-- CONTENT -->
  <div class="form-field-entity-searcher-container__content">
    <mat-form-field appearance="outline" class="sound-full-width">
      <input matInput [placeholder]="placeholder" [matAutocomplete]="auto" [formControl]="control"
        (blur)="inputModeService.setInputMode(false)" (focus)="inputModeService.setInputMode(true)" />
      <mat-autocomplete [panelWidth]="panelWidth" [displayWith]="displayWith.bind(this)" #auto="matAutocomplete"
        (optionSelected)="selectEntity($event)">
        <mat-option [style.height]="!IsNullOrUndefined(row?.template) ? 'unset' : 'a'" style="line-height: 34px;"
          *ngFor="let entity of entities" [value]="entity">

          <!-- DEFAULT TEMPLATE -->
          <ng-template #defaultRowTemplate let-entity>
            {{ labelSelector(entity) }}
            <ng-container *ngIf="!IsNullOrUndefined(subLabelSelector)">
              <span class="small">{{ subLabelSelector(entity) }}</span>
            </ng-container>
          </ng-template>

          <!-- CUSTOM TEMPLATE -->
          <ng-container *ngIf="!IsNullOrUndefined(row?.template); else showDefaultTemplate">
            <ng-container *ngTemplateOutlet="row.template; context: { $implicit: entity }"></ng-container>
          </ng-container>
          <ng-template #showDefaultTemplate>
            <ng-container *ngTemplateOutlet="defaultRowTemplate; context: { $implicit: entity }"></ng-container>
          </ng-template>

        </mat-option>
      </mat-autocomplete>

      <div matSuffix *ngIf="isLoading">
        <mat-spinner class="mr-4" [diameter]="20"></mat-spinner>
      </div>

      <mat-error *ngIf="required === true && control.hasError('required')">
        {{ error }}
      </mat-error>
    </mat-form-field>
  </div> <!-- /form-field-entity-searcher-container__content -->
</div> <!-- /form-field-entity-searcher-container -->