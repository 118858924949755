<sound-file-dropzone [required]="true" [allowedFileTypes]="['wav']" [multiple]="true" (click)="openUploadDialog()"
    [dropzoneClass]="isDropped === false ? 'tracks__drop-zone-container' : 'tracks__drop-zone-container tracks__drop-zone-container--dropped'"
    [defaultStructure]="false" [maximumFileSize]="1024 * 1024 * 200" (filesChanged)="openUploadDialog($event)" (hasError)="openUploadDialogWithErrors($event)">

    <div class="flex flex-row h-full items-center justify-center">
        <!-- ICON -->
        <mat-icon class="tracks__drop-zone-container__icon large" [svgIcon]="'upload'"></mat-icon>

        <!-- CAPTION -->
        <div class="tracks__drop-zone-container__caption ml-3"
            [ngClass]="{'tracks__drop-zone-container__caption--dropped': isDropped === true}">
            {{ 'Tracks.Upload' | translate }}
        </div>

        <!-- SUB CAPTION -->
        <ng-container *ngIf="isDropped === false">
            <div class="tracks__drop-zone-container__sub-caption ml-3">
                {{ 'Tracks.Drag and drop' | translate }}
            </div>
        </ng-container>
    </div>

</sound-file-dropzone>