import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';

import { ImageModule } from '../../image/image.module';
import { EntitySearcherModule } from '../base/entity-searcher/entity-searcher.module';
import { EntitySearcherArtistsComponent } from './entity-searcher-artists.component';
import { EntitySearcherRowDirective } from '../base/entity-searcher-row/entitiy-searcher-row.directive';
import { DefaultDatePipeModule } from '@app/shared/pipes/default-date.module';

@NgModule({
  imports: [
    SharedModule,

    EntitySearcherModule,
    ImageModule,
    DefaultDatePipeModule
  ],
  declarations: [EntitySearcherArtistsComponent],
  exports: [EntitySearcherArtistsComponent]
})
export class EntitySearcherArtistsModule { }
