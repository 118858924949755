<div class="form-fields-range-container h-auto">
  <!-- LABEL -->
  <div class="form-fields-range-container__label pb-7"
    *ngIf="!IsNullOrUndefinedOrEmpty(label) || !IsNullOrUndefinedOrEmpty(displayName)">
    {{ !IsNullOrUndefinedOrEmpty(label) ? label : displayName }} {{ required === true ? '*' : '' }}
  </div> <!-- /form-fields-checkbox-container__label -->

  <!-- CONTENT -->
  <div class="form-fields-range-container__content">
    <form class="slider-field" [formGroup]="getAsFormGroup(control)">
      <!-- LIMITS -->
      <ng-container
        *ngIf="shouldDisplayValue === true && shouldDisplayLimits && valuesDisplayType === 'Thumb'; else checkValuesDisplay">
        <span class="slider-minimum" *ngIf="shouldDisplay">
          {{ IsNullOrUndefinedOrEmpty(customLabelFormatter) ? min : customLabelFormatter(min) }}
        </span>
      </ng-container>

      <!-- VALUES EDGE DISPLAY -->
      <ng-template #checkValuesDisplay>
        <ng-container *ngIf="shouldDisplayValue === true && valuesDisplayType === 'Edge'">
          <span class="slider-minimum">
            {{ IsNullOrUndefinedOrEmpty(customLabelFormatter) ? getFirstValueInOrder(min, this.control.get('max')?.value) :
            customLabelFormatter(getFirstValueInOrder(min, this.control.get('max')?.value)) }} </span>
          <span class="slider-maximum">
            {{ IsNullOrUndefinedOrEmpty(customLabelFormatter) ? withMoreThanMax && getFirstValueInOrder(min,
            this.control.get('max')?.value, 'descending') === max ? getFirstValueInOrder(min, this.control.get('max')?.value, 'descending') + '+' :
            getFirstValueInOrder(min, this.control.get('max')?.value, 'descending') :
            customLabelFormatter(getFirstValueInOrder(min, this.control.get('max')?.value, 'descending'))}}
          </span>
        </ng-container>
      </ng-template>

      <!-- SLIDER -->
      <mat-slider [min]="min" [max]="max" step="1" [formControlName]="'currentMax'" #sliderMax
        [displayWith]="IsNullOrUndefinedOrEmpty(customLabelFormatter) ? withMoreThanMax ? formatLabelMax : null : customLabelFormatter"
        [style.width.px]="sliderWidth" #ngSlider><input matSliderThumb
          (input)="updateDeterminantAndLimitsDisplay('max', {source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}.value)"
          #ngSliderThumb="matSliderThumb" /></mat-slider>
      <!-- [disabled]="disabled" -->

      <!-- DIVIDER FOR PROPER SIZING IMPRESSION -->
      <mat-divider [style.width.px]="sliderWidth" [style.left.px]="0">
      </mat-divider>

      <!-- DIVIDER - MIDDLE DETERMINANT -->
      <mat-divider [style.width.px]="widthDistance" [style.left.px]="leftDisplacementDistance"
        [ngClass]="{'disabled': disabled, 'middle-determinant': true}">
      </mat-divider>
    </form>
  </div>
</div>