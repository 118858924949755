<!-- HOME -->
<sound-navigation-link link="home" icon="home" [displayName]="'Home.Home' | translate">
</sound-navigation-link>

<!-- BROWSE -->
<sound-navigation-link link="browse" icon="browse" [displayName]="'Home.Browse' | translate">
</sound-navigation-link>

<!-- DISCOVER -->
<sound-navigation-link link="discover" icon="discover" [displayName]="'Home.Discover' | translate">
</sound-navigation-link>

<!-- MY PROJECTS -->
<sound-navigation-link link="myprojects" icon="my_projects" [displayName]="'Home.My Projects' | translate">
</sound-navigation-link>

<!-- FAVOURITES -->
<sound-navigation-link link="favourites" icon="favorite" [displayName]="'Home.Favourites' | translate">
</sound-navigation-link>

<!-- HISTORY -->
<sound-navigation-link link="history" icon="history" [displayName]="'Home.History' | translate">
</sound-navigation-link>