import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@app/shared/shared.module';
import { JadFormFieldsMultiRangeModule } from '../../form-fields/multi-range/form-fields-multi-range.module';
import { JadFormFieldsSliderModule } from '../../form-fields/slider/form-fields-slider.module';
import { MusicPlayerVolumeComponent } from './music-player-volume.component';

@NgModule({
  imports: [
    SharedModule,
    MatIconModule,
    JadFormFieldsSliderModule
  ],
  declarations: [MusicPlayerVolumeComponent],
  exports: [MusicPlayerVolumeComponent]
})
export class MusicPlayerVolumeModule { }
