import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, merge, zip } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { I18nService } from './core/i18n.service';
import { Logger } from './core/logger.service';
import { BaseComponent } from './shared/base/components/base-component';
import { CookieDialogComponent } from './shared/components/cookie-dialog/cookie-dialog.component';
import { CountryCodeService } from './shared/services/country-code.service';
import { ForbiddenService } from './shared/services/local/forbidden.service';
import { MusicPlayerService } from './shared/services/local/music-player.service';
import { MusicFilterService } from './shared/services/local/music-filter.service';
import { DOCUMENT, KeyValue } from '@angular/common';
import { MetaService } from './shared/services/local/meta.service';
import { NotFoundService } from './shared/services/local/not-found.service';
import { AuthenticationService } from './core/authentication/authentication.service';

const log = new Logger('App');

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent extends BaseComponent implements OnInit {

  isBackEvent: boolean = false;

  @HostListener('window:popstate', ['$event']) onPopState(event: any) {
    this.musicService.pause(undefined, true);
    this.isBackEvent = true;
  }

  @HostListener('window:beforeunload', ['$event'])
  onReload(event: BeforeUnloadEvent) {
    this.musicService.pause(undefined, true);
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private forbiddenService: ForbiddenService,
    private i18nService: I18nService,
    private notFoundService: NotFoundService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private countryCodeService: CountryCodeService,
    private musicService: MusicPlayerService,
    private musicFilterService: MusicFilterService,
    private dialog: MatDialog,
    private metaService: MetaService,
    private authenticationService: AuthenticationService
  ) {
    super();

    const tokenInfo = authenticationService.getDecodedAccessToken(authenticationService?.credentials?.access_token);
    if (this.authenticationService.validateRefreshToken(tokenInfo) === false) {
      this.authenticationService.logout().subscribe();
    }
  }

  ngOnInit() {
    //this.checkCookies();

    if (environment.production) {
      Logger.enableProductionMode();
    }

    this.countryCodeService.loadCountryCode();

    this.i18nService.init(environment.supportedLanguages);

    try {
      this.document.documentElement.lang = this.i18nService.language.substring(0, this.i18nService.language.indexOf('-'));
    }
    catch {
    }

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    const onNavigationStart = this.router.events.pipe(filter(event => event instanceof NavigationStart));

    // Change page title on navigation or language change, based on route data
    merge(onNavigationStart)
      .subscribe(event => {
        this.forbiddenService.allow();
        this.notFoundService.notify(false);
        this.metaService.addDefaults();
      });

    // TODO: Better solution
    merge(onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;

          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter(route => route.outlet === 'primary')
      ).subscribe({
        next: (event: any) => {
          const queryParams = { ...event.queryParams.value };

          if (!this.isBackEvent) {
            this.musicFilterService.clearFiltersAndSort();

            for (const key of Object.keys(queryParams)) {
              this.musicFilterService.getFiltersFromKeyValuePair({ key, value: queryParams[key] })
                .map(q => this.musicFilterService.pushOrUpdateFilterOrSort(q));
            }
          }

          this.isBackEvent = false;

          const title = event.data['title'];

          if (!this.IsNullOrUndefinedOrEmpty(title)) {
            this.titleService.setTitle(this.translateService.instant(title));
          }
        }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  private checkCookies(): void {
    if (this.IsNullOrUndefined(localStorage.getItem(CookieDialogComponent.cookieKey))) {
      this.dialog.open(CookieDialogComponent, { maxWidth: '500px', maxHeight: '500px', disableClose: true })
        .afterClosed()
        .subscribe((cookieType: string) => {
          if (cookieType === 'Necessary') {
            // document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
            // window[disableStr] = true;
          }
        })
    } else {
      const cookieType = JSON.parse(localStorage.getItem(CookieDialogComponent.cookieKey));
      if (cookieType === 'Necessary') {
        // document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
        // window[disableStr] = true;
      }
    }
  }
}
