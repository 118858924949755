<sound-dialog-header class="dialog-header" [title]="'Table Settings'" (emitCloseClick)="dialogRef.close(null)">
</sound-dialog-header>

<sound-dialog-content>
  <p class="change-notification">
    {{'General.You can change the column order via drag and drop.' | translate }}
  </p>
  <div class="conf-table-dialog-container">
    <div fxLayout class="conf-table-dialog-menu">
      <div fxFlex="1 0 auto">
        <p>
          <a (click)="setToDefault()" class="default">{{'General.Set to default' | translate}}</a>
        </p>
      </div>
      <div fxFlex="0 1 auto">
        <p class="show-hide">
         {{'General.Hide / Show' | translate }}
        </p>
      </div>
    </div>
    <div class="conf-table-dialog-columns">

      <div cdkDropList class="columns" (cdkDropListDropped)="drop($event)">
        <div class="column" *ngFor="let column of columns" cdkDrag>
          <div fxLayout>
            <div fxFlex="1 0 auto" class="column__key">
              {{ column.value }}
            </div>
            <div fxFlex="0 1 auto" class="column_toggle" class="toggle-container">
              <mat-slide-toggle class="toggle" color="primary" [checked]="column.visibility"
                (change)="changeVisibility(column)">
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</sound-dialog-content>

<sound-dialog-footer>
  <div fxLayout>
    <div fxFlex="0 1 auto">
      <button mat-raised-button (click)="closeDialog(null)">{{'General.Discard' | translate }}</button>
    </div>
    <div fxFlex="0 1 auto">
      <button mat-raised-button (click)="closeDialog(columns)" color="primary">{{'General.Save' | translate }}</button>
    </div>
  </div>
</sound-dialog-footer>