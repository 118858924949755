import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppRoute } from '@app/shared/app.route.enum';
import { SidebarService } from '@app/shared/services/local/side-bar.service';
import { WaveformLoadingService } from '@app/shared/services/local/waveform-loading.service';
import { filter } from 'rxjs';
import { BaseSideBarComponent } from '../shared/base-side-bar-component';

@Component({
    selector: 'sound-side-bar-navigation',
    templateUrl: './side-bar-navigation.component.html',
    styleUrls: ['./side-bar-navigation.component.scss'],
    standalone: false
})
export class SideBarNavigationComponent extends BaseSideBarComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  isCreatorOrCuratorRoute: boolean;

  isCreatorRoute: boolean;

  isLoadingWaveform: boolean = false;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    protected sideBarService: SidebarService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private waveformLoadingService: WaveformLoadingService,
    private cdr: ChangeDetectorRef
  ) {
    super(sideBarService);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.isLoadingWaveform = this.waveformLoadingService.loading;

    super.addSubscription(
      this.waveformLoadingService.isLoading$.subscribe({
        next: (isLoading: boolean) => {
          this.isLoadingWaveform = isLoading;
          this.cdr.detectChanges();
        }
      })
    );

    this.checkForCreatorOrCuratorRoute();

    super.addSubscription(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd)
        )
        .subscribe(
          (event: NavigationEnd) => {
            this.checkForCreatorOrCuratorRoute();
          })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  scrollToFragment(fragment: string): void {
    const element = this.document.getElementById(fragment);
    const content = this.document.getElementById('content');
    const headerOffset = 200;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + content.scrollTop - headerOffset;
  
    content.scrollTo({
         top: offsetPosition,
         behavior: 'smooth'
    });
  }

  getNameOrNull(title: string): string {
    return this.isExpanded ? title : '';
  }

  checkForCreatorOrCuratorRoute(): void {
    if (this.router.url.includes(AppRoute.Creator) || this.router.url.includes(AppRoute.Curator)) {
      this.isCreatorOrCuratorRoute = true;
      this.router.url.includes(AppRoute.Creator) ? this.isCreatorRoute = true : this.isCreatorRoute = false;
    } else {
      this.isCreatorOrCuratorRoute = false;
    }
  }
}
