<sound-dialog-header
  [title]="'Upload.Uploading song' | translate: { index: currentFileIndex, total: data.audios.length}"
  class="dialog-header" [icon]="'upload'" [closeIsVisible]="false"></sound-dialog-header>

<sound-dialog-content>

  <ng-container *ngIf="isLoading === false">
    <div>

      <!-- TRACKS -->
      <ng-container
        *ngIf="data.hasErrors === false && hasUploaded === false && data?.audios?.length > 0; else messages">
        <div class="upload__tracks mb-6 grid">

          <!-- TITLE -->
          <div class="px-4 text-secondary-gray-60 text-xs">
            {{ 'Upload.Track title' | translate }}
          </div>

          <!-- IS ORIGINAL -->
          <div class="px-4 text-secondary-gray-60 text-xs">
            {{ 'Upload.Original' | translate }}
          </div>

          <!-- TRACK -->
          <ng-container *ngFor="let file of data.audios; let i = index;">

            <!-- NAME -->
            <div class="upload__tracks__track-meta upload__tracks__track-meta--left text-secondary-gray-20">
              {{ file?.original?.name }}
            </div>

            <!-- IS ORIGINAL -->
            <div class="upload__tracks__track-meta upload__tracks__track-meta--right">
              <jad-form-fields-checkbox [control]="form.at(i).get('isOriginal')" [required]="false">
              </jad-form-fields-checkbox>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- MESSAGES -->
      <ng-template #messages>

        <!-- ERRORS -->
        <ng-container *ngIf="data.hasErrors === true">
          <div class="upload__errors mb-6">
            <div class="text-secondary-gray-20">
              {{ errorMessage }}
            </div>
            <div class="text-primary-red-50">
              {{ data.erroneousFile?.name }}
            </div>
          </div>
        </ng-container>

        <!-- SUCCESS -->
        <ng-container *ngIf="hasUploaded === true">
          <div class="upload__success mb-6 text-primary-green-50">
            {{ 'Upload.The track(s) has been successfully uploaded' | translate }}
          </div>
        </ng-container>
      </ng-template>

      <!-- DROPZONE -->
      <ng-container>
        <div class="upload__dropzone mb-6">
          <sound-file-dropzone [required]="true" [allowedFileTypes]="['wav']" [multiple]="true"
            [dropzoneClass]="isDropped === false ? 'tracks__drop-zone-container' : 'tracks__drop-zone-container tracks__drop-zone-container--dropped'"
            [defaultStructure]="false" [maximumFileSize]="1024 * 1024 * 200" (filesChanged)="changeFiles($event)"
            (hasError)="handleErrors($event)" [shouldOpenDialogCustom]="true">

            <div class="flex flex-row h-full items-center justify-center">
              <!-- ICON -->
              <mat-icon class="tracks__drop-zone-container__icon large" [svgIcon]="'upload'"></mat-icon>

              <!-- CAPTION -->
              <div class="tracks__drop-zone-container__caption ml-3"
                [ngClass]="{'tracks__drop-zone-container__caption--dropped': isDropped === true}">
                {{ 'Upload.Add' | translate }}
              </div>

              <!-- SUB CAPTION -->
              <ng-container *ngIf="isDropped === false">
                <div class="tracks__drop-zone-container__sub-caption ml-3">
                  {{ 'Tracks.Drag and drop' | translate }}
                </div>
              </ng-container>
            </div>

          </sound-file-dropzone>
        </div>
      </ng-container>

      <!-- ARTIST -->
      <ng-container *ngIf="data?.isCurator">
        <div class="upload__artist-selector mb-6">
          <sound-entity-searcher-artists [label]="'Upload.Select your desired artist' | translate" [required]="true"
            [error]="'Upload.You need to specify an artist' | translate"
            [placeholder]="'Upload.Search for artists' | translate" (valueChanged)="setArtist($event)"
            [value]="!IsNullOrUndefined(selectedArtist) ? [selectedArtist] : []">
          </sound-entity-searcher-artists>
        </div>
      </ng-container>


      <!-- ACTIONS -->
      <div class="upload__actions flex flex-row justify-start items-center">
        <!-- CANCEL -->
        <sound-button-default [displayText]="'Tracks.Cancel' | translate" (onClick)="dialogRef.close(false)">
        </sound-button-default>
        <!-- SUBMIT -->
        <sound-button-default class="upload__actions__submit ml-4" [displayText]="'Tracks.Upload' | translate"
          [isDisabled]="data.hasErrors === true || hasUploaded === true || data.audios.length === 0"
          [colorClass]="'purple'" (onClick)="submit()">
        </sound-button-default>
      </div>

    </div>
  </ng-container>

  <!-- LOADING BAR -->
  <ng-container *ngIf="isLoading === true">
    <sound-loading-bar class="upload__loading-bar" [includeSpinner]="false" [fixed]="false" [color]="'#33FFA2'"
      [value]="progress"></sound-loading-bar>
  </ng-container>
</sound-dialog-content>