<!-- CONTAINER -->
<div class="partner__container flex flex-col items-start">

  <!-- HEADER -->
  <h1 class="partner__header mb-8">
    <strong>
      {{ 'Partner.Partner' | translate }}
    </strong>
  </h1>

  <!-- CONTENT -->
  <div class="partner__content text-left max-w-full md:max-w-2/3">

    <div class="html-content" [innerHTML]="'Partner.Content' | translate | safe: 'html'">
    </div>

  </div>

  <!-- BUTTON -->
  <div class="partner__button text-center mb-8 flex flex-row">
    <sound-button-default (onClick)="scrollUp()" [icon]="'arrow_simple_right'" [order]="'icon-last'" [displayText]="'Partner.Scroll Up' | translate">
    </sound-button-default>
  </div>

</div>