import { Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: '[soundLinkListTitle]',
    standalone: false
})
export class LinkListTitleDirective {

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(public template: TemplateRef<any>) { }

}
