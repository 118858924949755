import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { HttpCacheService } from '@app/core/http/http-cache.service';
import { I18nService } from '@app/core/i18n.service';
import { AppRoute } from '@app/shared/app.route.enum';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { LanguageHelper } from '@app/shared/functions/language.helper';
import { ApplicationUser } from '@app/shared/models/classes/ApplicationUser';
import { BasketService } from '@app/shared/services/basket.service';
import { LicensesService } from '@app/shared/services/licenses.service';
import { FavoritesService } from '@app/shared/services/local/favorites.service';
import { MusicFilter, MusicFilterService } from '@app/shared/services/local/music-filter.service';
import { TracksFilter } from '@app/tracks/list/tracks-list.component';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';

@Component({
    selector: 'sound-content-header',
    templateUrl: './content-header.component.html',
    styleUrls: ['./content-header.component.scss'],
    standalone: false
})
export class ContentHeaderComponent extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  user: ApplicationUser;

  Languages = LanguageHelper.GetLanguages();

  currentLanguage: any;

  basketIsActive: boolean = false;

  basketAmount: number = 0;

  isCuratorOrCreatorRoute: boolean = false;

  searchValue: string;

  window = window;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    public authenticationService: AuthenticationService,
    public router: Router,
    public i18nService: I18nService,
    private cdr: ChangeDetectorRef,
    private translationService: TranslateService,
    private basketService: BasketService,
    private filtersService: MusicFilterService,
    private httpCachingService: HttpCacheService,
    private favoritesService: FavoritesService
  ) {
    super();

    this.user = this.authenticationService.user;

    this.currentLanguage = LanguageHelper.GetLanguageByValueOrNull(this.i18nService.language);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {

    const initialSearchFilter = this.filtersService.filters.find(q => q.key === TracksFilter.Search);

    if (!this.IsNullOrUndefinedOrEmpty(initialSearchFilter)) {
      this.searchValue = initialSearchFilter.value;
    } else {
      this.searchValue = '';
    }

    super.addSubscription(
      this.filtersService.filters$.subscribe({
        next: (filters: MusicFilter[]) => {
          const searchFilter = filters.find(q => q.key === TracksFilter.Search);

          if (!this.IsNullOrUndefinedOrEmpty(searchFilter)) {
            this.searchValue = searchFilter.value;
          } else {
            this.searchValue = '';
          }
        }
      })
    );

    this.basketAmount = this.basketService.basketAmount;

    super.addSubscription(
      this.basketService.basketAmount$.subscribe({
        next: (newAmount: number) => {
          this.basketAmount = newAmount;

          this.cdr.detectChanges();
        }
      })
    );

    if (this.router.url.includes(AppRoute.Checkout)) {
      this.basketIsActive = true;
    }


    if (this.router.url.includes(AppRoute.Creator) || this.router.url.includes(AppRoute.Curator)) {
      this.isCuratorOrCreatorRoute = true;
    }

    // NAVIGATION START
    super.addSubscription(
      this.router.events.pipe(
        filter((event: any) => event instanceof NavigationStart)
      ).subscribe({
        next: (event: NavigationStart) => {
          // BASKET
          if (event.url.includes(AppRoute.Checkout)) {
            this.basketIsActive = true;
          } else {
            this.basketIsActive = false;
          }
        }
      })
    );

    // NAVIGATION END
    super.addSubscription(
      this.router.events.pipe(
        filter((event: any) => event instanceof NavigationEnd)
      ).subscribe({
        next: (event: NavigationEnd) => {
          // CURATOR OR CREATOR
          if (this.router.url.includes(AppRoute.Creator) || this.router.url.includes(AppRoute.Curator)) {
            this.isCuratorOrCreatorRoute = true;
          } else {
            this.isCuratorOrCreatorRoute = false;
          }
        }
      })
    );

  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  logout(): void {
    super.addSubscription(
      this.authenticationService
        .logout()
        .subscribe({
          next: () => {
            // this.basketService.empty();
            this.httpCachingService.clearCache(LicensesService.licenseGetUrl);

            this.favoritesService.clearFavorites();

            this.router.navigate([this.i18nService.getAbsoluteUrlWithCurrentLanguage(AppRoute.Login)]);
          }
        })
    );
  }

  setLanguage(language: string): void {
    this.translationService.use(language).subscribe();
    
    this.httpCachingService.cleanCache();

    let url = window.location.pathname.substring(1);
    const supportedLanguage = environment.supportedLanguages.find(q => q.locale === language);

    // Check if the url contains a language code
    if (environment.supportedLanguages.some(q => url.startsWith(q.shortLocale))) {
      url = `/${supportedLanguage.shortLocale}/${url.substring(url.indexOf('/') + 1)}`;
    } else {
      url = `/${url}`;
    }

    location.href = url;
  }

  setFilterSearchAndMaybeRedirect(search: string): void {
    if (!this.router.url.includes(AppRoute.Browse)) {
      if (!this.IsNullOrUndefinedOrEmpty(search)) {
        this.router.navigateByUrl(this.i18nService.getAbsoluteUrlWithCurrentLanguage(`${AppRoute.Browse}?search=${search}`));
      }
    } else {
      if (!this.IsNullOrUndefinedOrEmpty(search)) {
        const searchFilter = Object.assign(new MusicFilter(), {
          key: TracksFilter.Search,
          title: search,
          value: search,
          type: 'Include'
        });

        this.filtersService.pushOrUpdateFilterOrSort(searchFilter);
      }
    }
  }

}
