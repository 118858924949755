<div [ngSwitch]="view">
    <div class="relative xl:block  xs:hidden md:block top-2 left-[250px]">
        <mat-icon [svgIcon]="'close_navigation'" class="close-navigation" (click)="setNestedActiveSubSubNavigation(null)"></mat-icon>
      </div>
    <div *ngSwitchCase="SubSubNavigationView.Advanced">
        <sound-side-bar-advanced-subsubnavigation></sound-side-bar-advanced-subsubnavigation>
    </div>
    <div *ngSwitchDefault>
        <sound-side-bar-list-subsubnavigation [type]="view" [canExclude]="view === SubSubNavigationView.Mood || view === SubSubNavigationView.Instrument" [canMultiselect]="true"></sound-side-bar-list-subsubnavigation>
    </div>
</div>
