import { Component } from '@angular/core';

import { BaseMessageComponent } from '../shared/base-message.component';

@Component({
    selector: 'sound-messages-warning',
    templateUrl: './messages-warning.component.html',
    styleUrls: ['./messages-warning.component.scss'],
    standalone: false
})
export class MessagesWarningComponent extends BaseMessageComponent { }