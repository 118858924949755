<!-- LOGO -->

<!-- EXPANDED -->
<ng-container *ngIf="isExpanded === true; else collapsed;">
    <sound-navigation-link link="home" icon="soundtaxi_extended_large" iconClass="logo extended-logo--large">
    </sound-navigation-link>
</ng-container>

<!-- COLLAPSED -->
<ng-template #collapsed>
    <sound-navigation-link link="home" icon="soundtaxi_logo" iconClass="logo">
    </sound-navigation-link>
</ng-template>