<!-- WRAPPER -->
<div class="music-player__wrapper" [ngClass]="{'music-player__wrapper--hidden': IsNullOrUndefined(item) === true}">

  <!-- TOGGLE -->
  <div class="flex flex-row justify-end">
    <sound-button-default class="music-player__toggle" [icon]="toggleIcon" [iconClass]="'large'"
      [ngClass]="{'music-player__toggle--rotate': isExpanded === false}" [order]="'icon-first'"
      (onClick)="toggleMusicPlayer()"></sound-button-default>
  </div>

  <!-- CONTAINER -->
  <div
    class="music-player__container flex flex-row justify-between items-center py-4 md:px-6 sm:gap-4 lg:gap-5 xl:gap-10 bg-primary-dark-purple"
    [ngClass]="{'music-player__container--closed': isExpanded === false}">

    <!-- TRACK -->
    <div class="music-player__track flex-none md:flex-initial flex flex-row items-center xl:w-2/12">
      <sound-image class="music-player-image" [src]="image" [height]="'5.714rem'" [width]="'5.714rem'"></sound-image>
      <div class="ml-4 max-w-44" (click)="navigateToTrack()">
        <div class="music-player__track__markers">
          <sound-badge [type]="BadgeType.New" *ngIf="item?.variations[currentIndex]?.isNew"></sound-badge>
          <sound-badge [type]="BadgeType.Pro"
            [text]="item?.isPro ? item?.artist?.pro?.displayName : ('Browse.Non-Pro' | translate)">
          </sound-badge>
        </div>
        <div class="music-player__track__name" [matTooltipPosition]="'above'"
          [matTooltip]="item?.variations[currentIndex]?.displayName">
          {{ item?.variations[currentIndex]?.displayName }}</div>
        <div class="music-player__track__subtitle" (click)="navigateToArtist($event)">
          {{ item?.artist?.pseudonym }}
        </div>
      </div>
    </div>

    <!-- CONTROLS -->
    <div class="music-player__controls flex-initial flex flex-row items-center">
      <sound-music-player-controls></sound-music-player-controls>
    </div>

    <!-- WAVEFORM -->
    <div class="music-player__waveform flex-auto invisible h-0 w-0 overflow:hidden sm:h-auto sm:w-auto sm:overflow-auto sm:visible">
      <sound-waveform [fullWidth]="true" [id]="'-music-player'" [trackVariation]="item?.variations[currentIndex]" [style.display]="'block'" [width]="600"
        [peaksURL]="getPeaksURL()" [source]="getSource()" [duration]="item?.variations[currentIndex]?.length" [timestamps]="item?.timestamps" [representativeSegmentIndex]="item?.representativeSegmentIndex">
      </sound-waveform>
    </div>

    <!-- TIME -->
    <div class="music-player__time flex-initial text-sm flex-row items-center justify-start gap-2 relative hidden lg:flex">
      <div class="w-8">
        <span #timer class="absolute left-0 top-0">00:00</span>
      </div>
      <div>
        |
      </div>
      <div>
        {{ getLengthTime() }}
      </div>
    </div>

    <!-- VOLUME -->
    <!-- <div class="music-player__volume flex-initial">
      <sound-music-player-volume></sound-music-player-volume>
    </div> -->

    <!-- ACTIONS -->
    <div class="music-player__actions flex-initial">

      <sound-music-player-actions [item]="item" [currentIndex]="currentIndex"></sound-music-player-actions>

    </div>

  </div>

</div>