import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { Filter } from '@app/shared/base/models/filter';
import { SortOrder, __ } from '@app/shared/functions/object.functions';
import { Artist } from '@app/shared/models/classes/Artist';
import { Genre } from '@app/shared/models/classes/Genre';
import { Instrument } from '@app/shared/models/classes/Instrument';
import { Mood } from '@app/shared/models/classes/Mood';
import { Theme } from '@app/shared/models/classes/Theme';
import { SubSubNavigationView } from '@app/shared/models/enums/SubSubNavigationView.enum';
import { NormalizePipe } from '@app/shared/pipes/normalize.pipe';
import { ArtistService } from '@app/shared/services/artist.service';
import { GenreService } from '@app/shared/services/genre.service';
import { InstrumentService } from '@app/shared/services/instrument.service';
import { BaseMusicFilterSort, MusicFilter, MusicFilterService } from '@app/shared/services/local/music-filter.service';
import { SidebarService } from '@app/shared/services/local/side-bar.service';
import { MoodService } from '@app/shared/services/mood.service';
import { ThemeService } from '@app/shared/services/theme.service';
import moment from 'moment';
import { Moment } from 'moment';

export class SubNavigationDisplayItem {
    displayName: string;
    filterValue: string;
    filterKey: string;
    subValues: SubNavigationDisplayItem[];
    isExpanded: boolean = false;
}

@Component({
    selector: 'sound-side-bar-list-subsubnavigation',
    templateUrl: './side-bar-list-subsubnavigation.component.html',
    styleUrls: ['./side-bar-list-subsubnavigation.component.scss'],
    standalone: false
})
export class SideBarListSubsubnavigationComponent extends BaseComponent implements OnInit {
    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    genres: Genre[] = [];

    themes: Theme[] = [];

    instruments: Instrument[] = [];

    artists: Artist[] = [];

    moods: Mood[] = [];

    inADay: Moment = moment().add(1, 'days');

    itemsToDisplay: SubNavigationDisplayItem[] = [];

    currentLetter: string = '';

    activeFilters: MusicFilter[] = [];

    excludedFilters: MusicFilter[] = [];

    SubSubNavigationView = SubSubNavigationView;

    alphabet: string[] = 'abcdefghijklmnopqrstuvwxyz'.toLocaleUpperCase().split('');

    isLoading: boolean = false;

    // -----------------------------------------------------------------------------------------------------
    // @ INPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @Input() canExclude: boolean = false;

    @Input() canMultiselect: boolean = false;

    private _type: SubSubNavigationView;
    @Input()
    public get type(): SubSubNavigationView {
        return this._type;
    }
    public set type(value: SubSubNavigationView) {
        if (value !== this._type) {
            this._type = value;
            this.isLoading = true;
            this.loadEntities();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ VIEW CHILD/CHILDREN VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @ViewChild('subSubNavigationList') itemContainer: ElementRef;

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(
        private genreService: GenreService,
        private artistService: ArtistService,
        private moodService: MoodService,
        private themesService: ThemeService,
        private instrumentService: InstrumentService,
        private filtersService: MusicFilterService,
        private normalizePipe: NormalizePipe,
        private sideBarService: SidebarService
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ LIFE CYCLE HOOKS
    // -----------------------------------------------------------------------------------------------------

    ngOnInit() {
        this.loadEntities();

        this.updateListFilters(this.filtersService.filters);

        super.addSubscription(
            this.filtersService.filters$.subscribe({
                next: (filters: BaseMusicFilterSort[]) => {
                    this.updateListFilters(filters);
                }
            })
        )
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    loadEntities(): void {
        const seconds5 = moment().add(5, 'seconds').toDate();
        
        switch (this.type) {
            case SubSubNavigationView.Genre:
                super.addSubscription(
                    this.genreService.getAll('orderby=displayName&isActive=true', 0, 10000, null, false
                    ).subscribe({
                        next: (genres: Genre[]) => {
                            this.genres = genres
                                .filter(q => !this.IsNullOrUndefinedOrEmpty(q.displayName))
                                .map(q => {
                                    // filter
                                    q.subGenres = q.subGenres.filter(p => p.isActive === true);
                                    return q;
                                });
                            this.itemsToDisplay = this.genres.map(q => this.getDisplayValue(q));
                            this.isLoading = false;
                        }
                    })
                );
                break;
            case SubSubNavigationView.Artist:
                super.addSubscription(
                    this.artistService.getAll(`pseudonymStartsWith=${this.currentLetter}&orderby=pseudonym`, 0, 10000,
                        null, false)
                        .subscribe({
                            next: (artists: Artist[]) => {
                                this.artists = __.Sort(artists, q => q.pseudonym, SortOrder.Ascending);
                                this.itemsToDisplay = this.artists.map(q => this.getDisplayValue(q));
                                this.isLoading = false;
                            }
                        })
                );
                break;
            case SubSubNavigationView.Mood:
                super.addSubscription(
                    this.moodService.getAll('orderby=displayName&isActive=true', 0, 10000, null, false).subscribe({
                        next: (moods: Mood[]) => {
                            this.moods = moods;
                            this.itemsToDisplay = this.moods.map(q => this.getDisplayValue(q));
                            this.isLoading = false;
                        }
                    })
                );
                break;
            case SubSubNavigationView.Theme:
                super.addSubscription(
                    this.themesService.getAll('orderby=displayName&isActive=true', 0, 10000, null, false).subscribe({
                        next: (themes: Theme[]) => {
                            this.themes = themes;
                            this.itemsToDisplay = this.themes.map(q => this.getDisplayValue(q));
                            this.isLoading = false;
                        }
                    })
                );
                break;
            case SubSubNavigationView.Instrument:
                super.addSubscription(
                    this.instrumentService.getAll('orderby=displayName&isActive=true', 0, 10000, null, false).subscribe({
                        next: (instruments: Instrument[]) => {
                            this.instruments = instruments;
                            this.itemsToDisplay = this.instruments.map(q => this.getDisplayValue(q));
                            this.isLoading = false;
                        }
                    })
                );
                break;
        }
    }

    getDisplayValue(item: any): SubNavigationDisplayItem {
        let castedItem;
        switch (this.type) {
            case SubSubNavigationView.Artist:
                castedItem = (item as Artist);

                return Object.assign(new SubNavigationDisplayItem(), {
                    displayName: castedItem.pseudonym,
                    filterKey: 'artistPseudonym',
                    filterValue: castedItem.pseudonym
                });
            case SubSubNavigationView.Genre:
                castedItem = (item as Genre);

                return Object.assign(new SubNavigationDisplayItem(), {
                    displayName: castedItem.displayName,
                    filterKey: 'genreNormalizedName',
                    filterValue: castedItem.displayName,
                    subValues: this.IsNullOrUndefinedOrEmpty(castedItem.subGenres) ? [] :
                        castedItem.subGenres.map(q => Object.assign(new SubNavigationDisplayItem(), {
                            displayName: q.displayName,
                            filterKey: 'subGenreNormalizedName',
                            filterValue: q.displayName,
                        }))
                });
            case SubSubNavigationView.Instrument:
                castedItem = (item as Instrument);

                return Object.assign(new SubNavigationDisplayItem(), {
                    displayName: castedItem.displayName,
                    filterKey: 'instrumentNormalizedName',
                    filterValue: castedItem.displayName
                });
            case SubSubNavigationView.Mood:
                castedItem = (item as Mood);

                return Object.assign(new SubNavigationDisplayItem(), {
                    displayName: castedItem.displayName,
                    filterKey: 'moodNormalizedName',
                    filterValue: castedItem.displayName
                });
            case SubSubNavigationView.Theme:
                castedItem = (item as Theme);

                return Object.assign(new SubNavigationDisplayItem(), {
                    displayName: castedItem.displayName,
                    filterKey: 'themeNormalizedName',
                    filterValue: castedItem.displayName
                });
            default:
                return { displayName: '', filterValue: '', filterKey: '' } as SubNavigationDisplayItem;
        }
    }

    setNestedActiveSubSubNavigation(view: SubSubNavigationView): void {
        this.sideBarService.toggleSubBrowseSideBar(view);
      }

    switchStartLetterFilterValue(letter: string): void {
        const parentContainer = (this.itemContainer.nativeElement as HTMLDivElement);

        let child: HTMLDivElement = null;
        let offsetTopFirstChild = 0;

        if (!__.IsNullOrUndefinedOrEmpty(parentContainer.children.length) && parentContainer.children.length > 0) {
            offsetTopFirstChild = parentContainer.children[0].getBoundingClientRect().top;
        }

        for (let index = 0; index < parentContainer.children.length; index++) {
            const element = parentContainer.children[index];

            if (this.normalizePipe.transform((element.querySelector('.item a') as HTMLAnchorElement).innerText).startsWith(letter.toLowerCase())) {
                child = element as HTMLDivElement;
                break;
            }
        }

        if (!__.IsNullOrUndefined(child)) {
            const offsetTopInContainer = child.getBoundingClientRect().top;

            parentContainer.scroll(0, offsetTopInContainer - offsetTopFirstChild + 24); 
        }
    }

    hasAllSubItemsIncluded(parentValue: SubNavigationDisplayItem): boolean {
        return parentValue.subValues.every((subNavigationDisplayItem: SubNavigationDisplayItem) =>
            this.activeFilters.findIndex((filter: Filter) =>
                filter.key === subNavigationDisplayItem.filterKey && filter.value === subNavigationDisplayItem.filterValue) >= 0
        );
    }

    hasSubItemIncluded(value: SubNavigationDisplayItem): boolean {
        if (this.IsNullOrUndefined(value.subValues)) return false;
        return !this.IsNullOrUndefinedOrEmpty(this.activeFilters.find((filter: Filter) =>
            !this.IsNullOrUndefinedOrEmpty(
                value.subValues.find((subNavigationDisplayItem: SubNavigationDisplayItem) => filter.key === subNavigationDisplayItem.filterKey
                ))
            && !this.IsNullOrUndefinedOrEmpty(
                value.subValues.find((subNavigationDisplayItem: SubNavigationDisplayItem) => filter.value === subNavigationDisplayItem.filterValue
                ))
        ));
    }

    isIncluded(value: SubNavigationDisplayItem): boolean {
        return !this.IsNullOrUndefinedOrEmpty(this.activeFilters.find((filter: Filter) =>
            filter.key === value.filterKey && filter.value === value.filterValue
        ));
    }

    isExcluded(value: SubNavigationDisplayItem): boolean {
        return !this.IsNullOrUndefinedOrEmpty(this.excludedFilters.find((filter: Filter) =>
            filter.key === value.filterKey && filter.value === value.filterValue
        ));
    }


    /**
     * Toggles the current filter inclusion depending on whether it was included priorly or not, 
     * as well as relying on whether the said filter's children or parent are included already in the active filters.
     * 
     * Specifically, if a parent filter is included and any children are present in active filters, all the children will 
     * be removed. If a child instead is added, and all other siblings are present in active filters, then all children will be 
     * removed and the parent added instead. For the cases in which no such connection is present, the filter shall be added
     * or removed as normal.
     * 
     * Please note that the filter is then sent to the music filter service which will in the end reflect the changes in the local filters list 
     * through updateListFilters
     *
     * {SubNavigationDisplayItem} value
     * {SubNavigationDisplayItem} [parentValue]
     * SideBarListSubsubnavigationComponent
     */
    toggleFilterInclude(value: SubNavigationDisplayItem, parentValue?: SubNavigationDisplayItem): void {
        if (this.canMultiselect || this.IsNullOrUndefinedOrEmpty(this.activeFilters.find((q: MusicFilter) => q.key === value.filterKey))) {
            const filterIndex = this.activeFilters.findIndex((filter: MusicFilter) => filter.key === value.filterKey && filter.value === value.filterValue);

            // For item
            if (filterIndex === -1) {
                if (this.isExcluded(value)) {
                    this.toggleFilterExclude(value);
                }

                // Remove all sub items when selecting parent
                if (!this.IsNullOrUndefinedOrEmpty(value.subValues)) {
                    for (const subValue of value.subValues) {
                        const subFilterIndex = this.activeFilters.findIndex((filter: Filter) =>
                            filter.key === subValue.filterKey && filter.value === subValue.filterValue);

                        if (subFilterIndex >= 0) {
                            this.filtersService.removeFilterByDisplayName(subValue.displayName);
                        }
                    }
                }

                const newActiveFilter = Object.assign(new MusicFilter(), {
                    key: value.filterKey,
                    value: value.filterValue,
                    title: value.displayName,
                    type: 'Include'
                });

                // Add item to filter list
                this.filtersService.pushOrUpdateFilterOrSort(newActiveFilter);
            } else {
                // Remove item from filter list
                this.filtersService.removeFilterByDisplayName(value.displayName);
            }

            // For parent item
            if (!this.IsNullOrUndefinedOrEmpty(parentValue)) {
                // Remove parent from filter list when at least one sub item is being selected
                const parentFilterIndex = this.activeFilters.findIndex((filter: Filter) =>
                    filter.key === parentValue.filterKey && filter.value === parentValue.filterValue
                );

                if (parentFilterIndex >= 0) {
                    this.filtersService.removeFilterByDisplayName(parentValue.displayName);
                }

                // Remove all sub items and add parent item when all sub items are being selected
                if (this.hasAllSubItemsIncluded(parentValue)) {

                    for (const subValue of parentValue.subValues) {
                        this.toggleFilterInclude(subValue);
                    }

                    const newActiveFilter = Object.assign(new MusicFilter(), {
                        key: parentValue.filterKey,
                        value: parentValue.filterValue,
                        title: parentValue.displayName,
                        type: 'Include'
                    });

                    this.filtersService.pushOrUpdateFilterOrSort(newActiveFilter);
                }
            }
        }
    }

    /**
     * Toggles the current excluded filter depending on whether it has already been activated or not
     * The change is then sent to the filters service, from where the new filters structure will be updated with updateListFilters
     *
     * {SubNavigationDisplayItem} value
     * SideBarListSubsubnavigationComponent
     */
    toggleFilterExclude(value: SubNavigationDisplayItem): void {
        if (this.canExclude) {
            const filterIndex = this.excludedFilters.findIndex((filter: MusicFilter) => filter.key === value.filterKey && filter.value === value.filterValue);

            if (filterIndex === -1) {
                if (this.isIncluded(value)) {
                    this.toggleFilterInclude(value);
                }

                const newExcludedFilter = Object.assign(new MusicFilter(), {
                    key: value.filterKey,
                    value: value.filterValue,
                    title: value.displayName,
                    type: 'Exclude'
                });

                this.filtersService.pushOrUpdateFilterOrSort(newExcludedFilter);
            } else {
                this.filtersService.removeFilterByDisplayName(value.displayName);
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PRIVATE METHODS
    // -----------------------------------------------------------------------------------------------------


    /**
     * Updates the active filters and excluded filters based on the changes made in the music filters service
     *
     * {BaseMusicFilterSort[]} filters
     * SideBarListSubsubnavigationComponent
     */
    private updateListFilters(filters: BaseMusicFilterSort[]): void {
        const relevantExcludedFilters = filters.filter(q => q.type === 'Exclude' && (q.key === 'instrumentNormalizedName' || q.key === 'moodNormalizedName'));

        const relevantIncludedFilters = filters.filter(q =>
            q.type === 'Include' &&
            (q.key === 'artistPseudonym' ||
                q.key === 'genreNormalizedName' ||
                q.key === 'subGenreNormalizedName' ||
                q.key === 'instrumentNormalizedName' ||
                q.key === 'moodNormalizedName' ||
                q.key === 'themeNormalizedName'));


        this.activeFilters = [...relevantIncludedFilters.map(q => q as MusicFilter)];
        this.excludedFilters = [...relevantExcludedFilters.map(q => q as MusicFilter)];
    }
}