import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@app/shared/shared.module';
import { MusicPlayerControlsComponent } from './music-player-controls.component';

@NgModule({
  imports: [
    SharedModule,
    MatIconModule
  ],
  declarations: [MusicPlayerControlsComponent],
  exports: [MusicPlayerControlsComponent]
})
export class MusicPlayerControlsModule { }
