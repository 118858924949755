import { Component, ContentChild, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { __ } from '@app/shared/functions/object.functions';
import { BaseModel } from '@app/shared/models/classes/BaseModel';
import { InputModeService } from '@app/shared/services/input-mode.service';
import { Observable, of } from 'rxjs';
import { debounceTime, finalize, mergeMap } from 'rxjs/operators';

import { EntitySearcherRowDirective } from '../entity-searcher-row/entitiy-searcher-row.directive';

@Component({
    selector: 'sound-entity-searcher',
    templateUrl: './entity-searcher.component.html',
    styleUrls: ['./entity-searcher.component.scss'],
    standalone: false
})
export class EntitySearcherComponent<T extends BaseModel> extends BaseComponent implements OnInit {
  entities: T[] = [];

  control: UntypedFormControl;

  @Input() label: string = 'Entity';

  @Input() error: boolean = null;

  @Input() displayName: string = 'Entity';

  @Input() getEntities$: (value: string) => Observable<T[]>;

  @Input() labelSelector: (value: T) => any;

  @Input() subLabelSelector: (value: T) => any;

  @Input() exceptEntities: T[] = [];

  @Input() panelWidth: string = undefined;

  @Input() placeholder: string = 'Entity';

  @Input() required: boolean = true;

  private _disabled: boolean = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;

    if (!__.IsNullOrUndefined(value)) {
      if (value === true) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  @Input() customOptionClass: string;

  @Input() displaySelectedValue: boolean = false;

  customOptionClasses: any;

  @ContentChild(EntitySearcherRowDirective, { static: true }) row: EntitySearcherRowDirective<any>;

  @Output() valueChanged: EventEmitter<T> = new EventEmitter<T>();

  private previousSearchValue: string;

  private _value: T;

  @Input()
  get value(): T {
    return this._value;
  }
  set value(value: T) {
    this._value = value;

    if (!__.IsNullOrUndefined(value)) {
      this.control.setValue(value, { emitEvent: false });
    } else {
      this.control.setValue(null, { emitEvent: false });
    }
  }

  constructor(
    private fb: UntypedFormBuilder,
    public inputModeService: InputModeService
  ) {
    super();

    this.control = new UntypedFormControl();
  }

  @Input() idSelector: (value: T) => any = (value: T) => value.id;

  @Input() valueSelector: (value: T) => any = (value: T) => value.id;

  ngOnInit() {


    if (!__.IsNullOrUndefinedOrEmpty(this.customOptionClass)) {
      this.customOptionClasses = { 'sound-full-width': true };
      this.customOptionClasses[this.customOptionClass] = true;
    }

    if (this.required === true) {
      this.control.setValidators(Validators.required);
    }

    super.addSubscription(
      this.control
        .valueChanges.pipe(
          debounceTime(300),
          mergeMap((value: any) => {
            if (value === this.previousSearchValue || typeof value.valueOf() !== 'string') {
              return of([]);
            }
            this.previousSearchValue = value;
            if (__.IsNullOrUndefinedOrEmpty(value)) {
              return of([]);
            } else {
              this.isLoading = true;
              return this.getEntities$(value).pipe(finalize(() => { this.isLoading = false }));
            }
          }),
        )
        .subscribe((entities: T[]) => {
          if (!__.IsNullOrUndefinedOrEmpty(this.exceptEntities)) {
            entities = entities.filter(
              (q: T) => this.exceptEntities.findIndex((a: T) => this.idSelector(q) === this.idSelector(a)) === -1
            );
          }
          this.entities = entities;
        })
    );
  }

  resetValue(): void {
    this.control.reset();
  }

  displayWith(value: any): string {
    return this.labelSelector(value);
  }

  selectEntity($event: any) {
    if (this.displaySelectedValue === false) {
      this.control.reset();
    }
    this.valueChanged.emit($event.option.value);
  }
}
