<!-- ACTIONS -->
<div class="music-player__actions flex flex-row items-center">

  <!-- MORE -->
  <sound-buttons-menu [sizeClass]="'large'" [colorClass]="'transparent'">
    <ng-template soundButtonsMenuLabel>
      <mat-icon [ngClass]="['large']" [svgIcon]="'ellipsis'"></mat-icon>
    </ng-template>

    <!-- FAVORITE-->
    <sound-buttons-menu-action (onClick)="toggleFavorites()" [order]="'icon-last'"
      [iconClass]="isFavorite() === true ? 'favorite' : 'menu'"
      [displayText]="'MusicPlayer.Add To Favourites' | translate" colorClass="default" sizeClass="large"
      icon="favorite">
    </sound-buttons-menu-action>

    <!-- ADD TO PROJECT -->
    <sound-buttons-menu-action (onClick)="openAddToProjectDialog()" [order]="'icon-last'" [iconClass]="'menu'"
      [displayText]="'MusicPlayer.Add To Project' | translate" colorClass="default" sizeClass="large" icon="my_songs">
    </sound-buttons-menu-action>

    <!-- SIMILARITY SEARCH -->
    <sound-buttons-menu-action (onClick)="openSimilaritySearchDialog()" [order]="'icon-last'" [iconClass]="'menu'"
      [displayText]="'MusicPlayer.Similarity Search' | translate" colorClass="default" sizeClass="large"
      icon="similarity">
    </sound-buttons-menu-action>

    <!-- SHOPPING CART -->
    <sound-buttons-menu-action (onClick)="openLicenseSelectorDialog()" [order]="'icon-last'" [iconClass]="'menu'"
      [displayText]="'Tracks.Add to basket' | translate" colorClass="default" sizeClass="large"
      icon="shopping_cart_plus">
    </sound-buttons-menu-action>

    <!-- SHARE -->
    <!-- <sound-buttons-menu-action (onClick)="openShareDialog()" [order]="'icon-last'" [iconClass]="'menu'"
      [displayText]="'MusicPlayer.Share' | translate" colorClass="default" sizeClass="large" icon="share">
    </sound-buttons-menu-action> -->
  </sound-buttons-menu>

  <!-- SIMILARITY SEARCH -->
  <mat-icon class="music-player__action large mobile-hidden" [matTooltipPosition]="'above'"
    [matTooltip]="'MusicPlayer.Similarity Search' | translate" [svgIcon]="'similarity'"
    (click)="openSimilaritySearchDialog()">
  </mat-icon>

  <!-- DOWNLOAD -->
  <sound-download-button class="music-player__action mobile-hidden"
    (downloadClicked)="downloadTrackVariationByResolution($event)">
  </sound-download-button>

  <!-- SHOPPING CART -->
  <mat-icon class="music-player__action large mobile-hidden" [svgIcon]="'shopping_cart_plus'"
    (click)="openLicenseSelectorDialog()">
  </mat-icon>

</div>