<sound-dialog-header title="You have pending changes" class="dialog-header"
  (emitCloseClick)="dialogRef.close(null)">
</sound-dialog-header>

<div style="margin-top: 24px; min-width: 500px; margin-bottom: 24px;">
  {{'General.Are you sure to leave the page without saving?' | translate}}
</div>

<sound-dialog-footer>
  <div class="flex flex-row">
    <sound-button-default class="flex-initial" (onClick)="dialogRef.close(null)">Continue editing
    </sound-button-default>
    <sound-buttons-submit class="ml-1 flex-initial" (onClick)="dialogRef.close(true)">Leave without saving
    </sound-buttons-submit>
  </div>

</sound-dialog-footer>