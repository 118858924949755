import { Component, ContentChild, ContentChildren, Input, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { LinkListTitleDirective } from './link-list-title/link-list-title.directive';
import { LinkListLinkComponent } from './link/link-list-link.component';

@Component({
    selector: 'sound-link-list',
    templateUrl: './link-list.component.html',
    styleUrls: ['./link-list.component.scss'],
    standalone: false
})
export class LinkListComponent extends BaseComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------
  @Input() listDisplayClass: string;

  // -----------------------------------------------------------------------------------------------------
  // @ CONTENT CHILDREN VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private _title: LinkListTitleDirective;
  @ContentChild(LinkListTitleDirective, { static: true })
  get title(): LinkListTitleDirective {
    return this._title;
  }
  set title(value: LinkListTitleDirective) {
    this._title = value;
  }

  private _links: QueryList<LinkListLinkComponent>;

  @ContentChildren(LinkListLinkComponent, { descendants: true })
  get links(): QueryList<LinkListLinkComponent> {
    return this._links;
  }
  set links(links: QueryList<LinkListLinkComponent>) {
    this._links = links;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    public router: Router
  ) { 
    super();
  }

}
