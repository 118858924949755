import { __ } from '@app/shared/functions/object.functions';
import { BPMRanges } from '../enums/BPMRanges.enum';

export class BPMRangesHelper {
  static BPMRanges: any[] = [
    {
      displayName: BPMRanges.Slow,
      value: {
          min: 0,
          max: 64
      }
    },
    {
        displayName: BPMRanges.MedSlow,
        value: {
            min: 65,
            max: 84
        }
      },
      {
        displayName: BPMRanges.Medium,
        value: {
            min: 85,
            max: 115
        }
      },
      {
        displayName: BPMRanges.MedFast,
        value: {
            min: 116,
            max: 134
        }
      },
      {
        displayName: BPMRanges.Fast,
        value: {
            min: 135,
            max: 180
        }
      },
  ];

  static GetBPMRangeByNameOrNull(displayName: string): any {
    const result = BPMRangesHelper.BPMRanges.find((range: any) => range.displayName === displayName);

    if (__.IsNullOrUndefinedOrEmpty(result)) {
      return null;
    }

    return result;
  }

  static GetBPMRangeByValueOrNull(value: {min: number, max: number}): any {
    if (__.IsNullOrUndefinedOrEmpty(value)) {
        return null;
    }

    const result = BPMRangesHelper.BPMRanges.find((range: any) => range.value.min === value.min && range.value.max === value.max);
    
    if (__.IsNullOrUndefinedOrEmpty(result)) {
      return null;
    }

    return result;
  }
}
