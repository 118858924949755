import { ChangeDetectorRef, Component, OnInit, HostListener } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { MusicPlayerService } from '@app/shared/services/local/music-player.service';
import { MusicPlaylistService } from '@app/shared/services/local/music-playlist.service';


@Component({
    selector: 'sound-music-player-controls',
    templateUrl: './music-player-controls.component.html',
    styleUrls: ['./music-player-controls.component.scss'],
    standalone: false
})
export class MusicPlayerControlsComponent extends BaseComponent implements OnInit {

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  isPlaying: boolean = false;

  waveformId: string;

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private musicPlayerService: MusicPlayerService,
    private musicPlaylistService: MusicPlaylistService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    super.addSubscription(
      this.musicPlayerService
        .play$
        .subscribe({
          next: (isPlaying: { id: string, isPlaying: boolean }) => {
            this.isPlaying = isPlaying?.isPlaying;
            this.waveformId = isPlaying?.id;
            this.cdr.detectChanges();
          }
        })
    );
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // ------------------------------------------------------------------------------------------------------

  play(): void {
    this.musicPlayerService.play(this.waveformId);

  }
  pause(): void {
    this.musicPlayerService.pause(this.waveformId);
  }

  loadNextTrack(): void {
    this.musicPlaylistService.playNext();
  }

  loadPreviousTrack(): void {
    this.musicPlaylistService.playPrevious();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ KEYBOARD SHORTCUTS
  // ------------------------------------------------------------------------------------------------------

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    const activeElement = document.activeElement;

    // Überprüfe, ob der Benutzer sich in einem Textfeld befindet
    if (activeElement instanceof HTMLInputElement || activeElement instanceof HTMLTextAreaElement || (activeElement as HTMLElement).isContentEditable) {
      return; // Bricht ab, wenn sich der Fokus in einem Textfeld befindet
    }

    switch (event.key) {
      case ' ': // Leertaste zum Abspielen/Pausieren
        event.preventDefault(); // Verhindert das Scrollen durch Leertaste
        if (this.isPlaying) {
          this.pause();
        } else {
          this.play();
        }
        break;

      case 'ArrowRight': // Nächster Track
        this.loadNextTrack();
        break;

      case 'ArrowLeft': // Vorheriger Track
        this.loadPreviousTrack();
        break;

    }
  }
}
