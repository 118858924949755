import { NgModule } from '@angular/core';
import { RouterModule, Routes, Route as ngRoute } from '@angular/router';
import { ArtistEnquiryComponent } from './artist-enquiry/artist-enquiry.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

import { DataProtectionComponent } from './core/data-protection/data-protection.component';
import { ImprintComponent } from './core/imprint/imprint.component';
import { Route } from './core/Routes';
import { TermsAndConditionsComponent } from './core/terms-and-conditions/terms-and-conditions.component';
import { DolbyAtmosComponent } from './dolby-atmos/dolby-atmos.component';
import { PartnerComponent } from './partner/partner.component';
import { RightToCancelComponent } from './right-to-cancel/right-to-cancel.component';
import { RoyaltyFreeMusicComponent } from './royalty-free-music/royalty-free-music.component';
import { AppRoute } from './shared/app.route.enum';
import { Role } from './shared/models/classes/Role';
import { environment } from '@env/environment';
import { __ } from './shared/functions/object.functions';

const languages = environment.supportedLanguages;

export function withLanguages(path: string, options: Route): ngRoute[] {
  return languages.map(q => {
    return Object.assign({ path: `${q.shortLocale}/${path}` }, options);
  });
}

const routes: Routes = [
  // Default route
  {
    path: '',
    redirectTo: `/de/${AppRoute.Home}`,
    pathMatch: 'full'
  },

  // Error pages routes
  ...withLanguages(AppRoute.Forbidden, { loadChildren: () => import('./core/forbidden/forbidden.module').then(m => m.ForbiddenModule) }),

  // Authenticated routes
  Route.withBaseLayoutAndAuthentication([
    // Admin routes
    { path: AppRoute.Admin, data: { roles: [Role.Administrator, Role.CompanyAdministrator, Role.Curator] }, loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },

    // Creator routes
    { path: AppRoute.Creator, data: { roles: [Role.Administrator, Role.CompanyAdministrator, Role.Creator] }, loadChildren: () => import('./creator/creator.module').then(m => m.CreatorModule) },

    // Curator routes
    { path: AppRoute.Curator, data: { roles: [Role.Administrator, Role.CompanyAdministrator, Role.Curator] }, loadChildren: () => import('./curator/curator.module').then(m => m.CuratorModule) },

    // End user facing routes
    ...withLanguages(AppRoute.MyAccount, { loadChildren: () => import('./account/account.module').then(m => m.AccountModule) }),
    ...withLanguages(AppRoute.Favourites, { loadChildren: () => import('./favourites/favourites.module').then(m => m.FavouritesModule) })
  ]),

  // Unauthenticated routes
  Route.withBaseLayout([
    ...withLanguages(AppRoute.Home, { loadChildren: () => import('./home/home.module').then(m => m.HomeModule) }),
    ...withLanguages(AppRoute.Checkout, { loadChildren: () => import('./basket/basket.module').then(m => m.BasketModule) }),
    ...withLanguages(AppRoute.History, { loadChildren: () => import('./history/history.module').then(m => m.HistoryModule) }),
    ...withLanguages(AppRoute.Browse, { loadChildren: () => import('./browse/browse.module').then(m => m.BrowseModule) }),
    ...withLanguages(AppRoute.Discover, { loadChildren: () => import('./discover/discover.module').then(m => m.DiscoverModule) }),
    ...withLanguages(AppRoute.Purchases, { loadChildren: () => import('./purchases/purchases.module').then(m => m.PurchasesModule) }),
    ...withLanguages(AppRoute.Playlists, { loadChildren: () => import('./playlists/playlists.module').then(m => m.PlaylistsModule) }),
    ...withLanguages(AppRoute.Artists, { loadChildren: () => import('./artists/artists.module').then(m => m.ArtistsModule) }),
    ...withLanguages(AppRoute.Tracks, { loadChildren: () => import('./tracks/tracks.module').then(m => m.TracksModule) }),
    ...withLanguages(AppRoute.SimilaritySearch, { loadChildren: () => import('./similarity-search/similarity-search.module').then(m => m.SimilaritySearchModule) }),
    ...withLanguages(AppRoute.Register, { loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) }),
    ...withLanguages(AppRoute.Login, { loadChildren: () => import('./login/login.module').then(m => m.LoginModule) }),
    { 
      path: AppRoute.Callback,
      loadChildren: () => import('./callback/callback.module').then(m => m.CallbackModule)
    },
    ...withLanguages(AppRoute.Licenses, { loadChildren: () => import('./licenses/licenses.module').then(m => m.LicensesModule) }),
    ...withLanguages(AppRoute.Help, { loadChildren: () => import('./help/help.module').then(m => m.HelpModule) }),
    ...withLanguages(AppRoute.Work, { loadChildren: () => import('./work/work.module').then(m => m.WorkModule) }),
    ...withLanguages(AppRoute.Imprint, { component: ImprintComponent, data: { title: 'Title.Legal Notice | Soundtaxi' } }),
    ...withLanguages(AppRoute.PrivacyPolicy, { component: DataProtectionComponent, data: { title: 'Title.Data protection | Soundtaxi' } }),
    ...withLanguages(AppRoute.TermsOfUse, { component: TermsAndConditionsComponent, data: { title: 'Title.Terms Of Use | Soundtaxi' } }),
    ...withLanguages(AppRoute.ContactUs, { component: ContactUsComponent, data: { title: 'Title.Contact Us | Soundtaxi' } }),
    ...withLanguages(AppRoute.ArtistEnquiry, { component: ArtistEnquiryComponent, data: { title: 'Title.Artist Enquiry | Soundtaxi' } }),
    ...withLanguages(AppRoute.RoyaltyFreeMusic, { component: RoyaltyFreeMusicComponent, data: { title: 'Title.Royalty Free Music | Soundtaxi' } }),
    ...withLanguages(AppRoute.RightToCancel, { component: RightToCancelComponent, data: { title: 'Title.Right To Cancel | Soundtaxi' } }),
    ...withLanguages(AppRoute.DolbyAtmos, { component: DolbyAtmosComponent, data: { title: 'Title.Dolby Atmos | Soundtaxi' } }),
    ...withLanguages(AppRoute.Partner, { component: PartnerComponent, data: { title: 'Title.Partner | Soundtaxi' } }),
    ...withLanguages(AppRoute.MyProjects, { loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule) }),
  ]),
  { path: '**', redirectTo: `/de/${AppRoute.Home}` }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
