<!-- CONTAINER -->
<div class="music-player-volume__container flex flex-row gap-1 lg:gap-5 justify-center items-center">

  <!-- MUTE TOGGLE -->
  <div class="music-player-volume__toggle flex-initial flex flex-row justify-center">
    <mat-icon class="large cursor-pointer" [svgIcon]="muteToggleIcon" (click)="toggleMute()">
    </mat-icon>
  </div>

    <!-- VOLUME SLIDER -->
    <div class="music-player-volume__slider w-10 sm:w-24">
      <jad-form-fields-slider [sliderWidth]="isDesktop ? 110 : 45" [control]="volumeForm" [step]="1" [shouldDisplayValue]="false"
            [min]="0" [shouldDisplayLimits]="false" [withMoreThanMax]="false" [max]="100"
            [valuesDisplayType]="'Thumb'">
        </jad-form-fields-slider>
    </div>

  </div>