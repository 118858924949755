<div class="py-10 sm:py-10 md:py-5 lg:py-5 xl:py-5 2xl:py-5">
    <div class="px-5 mb-5">
        <jad-form-fields-multi-range [control]="form.get('bpm')" [label]="'Browse.BPM' | translate" [step]="5"
            [min]="bpmMin" [withMoreThanMax]="true" [max]="bpmMax" [valuesDisplayType]="'Edge'"
            [sliderWidth]="sliderWidth">
        </jad-form-fields-multi-range>

        <div>
            <div class="gap-2 flex flex-wrap pb-2">
                <sound-tags-label *ngFor="let range of BPMRanges.slice(0, 3)"
                    [isActive]="checkActiveBpmRange(range.value)"
                    [displayText]="getTranslationEquivalent(range.displayName) | translate"
                    (onClick)="setActiveRange(range.value)"></sound-tags-label>
                <sound-tags-label *ngFor="let range of BPMRanges.slice(3)" [isActive]="checkActiveBpmRange(range.value)"
                    [displayText]="getTranslationEquivalent(range.displayName) | translate"
                    (onClick)="setActiveRange(range.value)"></sound-tags-label>
            </div>

        </div>

    </div> 

    <mat-divider class="sub-sub-sidebar__divider pt-5"></mat-divider>

    <div class="px-5 mb-5">
        <jad-form-fields-multi-range [control]="form.get('duration')" [label]="'Browse.Duration' | translate" [step]="5"
            [min]="durationMin" [withMoreThanMax]="true" [max]="durationMax" [customLabelFormatter]="durationFormatter"
            [valuesDisplayType]="'Edge'" [sliderWidth]="sliderWidth">
        </jad-form-fields-multi-range>
    </div>

    <mat-divider class="sub-sub-sidebar__divider pt-5"></mat-divider>

    <div class="px-5">
        <span>{{ 'Browse.Vocals' | translate }}</span>

        <div class="gap-2 flex flex-wrap mt-4">
            <sound-tags-label *ngFor="let vocal of Vocals" [displayText]="vocal.displayName | translate"
                [isActive]="checkActiveVocals(vocal.value)" (onClick)="setVocals(vocal.value)"></sound-tags-label>
        </div>
    </div>
</div>