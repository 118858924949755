import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { MetaService } from '@app/shared/services/local/meta.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-ai-powered',
    templateUrl: './ai-powered.component.html',
    styleUrls: ['./ai-powered.component.scss'],
    standalone: false
})
export class AiPoweredComponent extends BaseComponent implements OnInit, OnDestroy {

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private metaService: MetaService,
    private translate: TranslateService
  ) {
    super();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    // Setzt das Hintergrundbild, wenn die Komponente geladen wird
    this.document.body.style.background = 'linear-gradient(360deg, transparent 60%, #5C2473 130%), url(https://imagedelivery.net/3cqLyQwu3OCaEPcs2GMsQQ/5ebbe8dc-7a3a-45d0-0575-ee716687ae00/XXL)';

    // SEO Meta-Tags setzen
    this.metaService.addDefaults({
      title: this.translate.instant('SEO.Ai Powered.Title'),
      description: this.translate.instant('SEO.Ai Powered.Description'),
      keywords: this.translate.instant('SEO.Ai Powered.Keywords')
    });
  }

  ngOnDestroy(): void {
    // Hintergrundbild beim Verlassen der Seite zurücksetzen
    this.document.body.style.background = 'none';
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // ------------------------------------------------------------------------------------------------------

  scrollUp(): void {
    const content = this.document.getElementById('content');
    if (content) {
      content.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
